.wp-cto-supporters {
  &__wrapper {
    background-color: color('pale-gray');

    @include query('mobile') {
      padding: rem(70) 0 rem(60) 0;
    }

    @include query('tablet-up') {
      padding: rem(60) 0 rem(80) 0;
    }

    @include query('laptop-up') {
      padding: rem(75) 0 rem(100) 0;
    }

    @include query('desktop') {
      padding: rem(100) 0 rem(150) 0;
    }
  }

  &__title {
    @include font-stack($font-secondary);

    color: color('primary');
    margin-top: 0;

    @include query('mobile') {
      @include font-size('card-slider-title-mobile');
    }

    @include query('tablet-up') {
      @include font-size('card-slider-title-tablet');
    }

    @include query('laptop-up') {
      @include font-size('card-slider-title-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('card-slider-title-desktop');
    }
  }

  &__inner {
    @include container();
  }

  &__container {
    @include query('mobile') {
      width: 100%;
    }

    @include query('tablet-up') {
      @include offset(1);
      @include column(10);
    }
  }

  &__holder {
    position: relative;

    & + & {
      @include query('mobile') {
        margin-top: rem(35);
      }

      @include query('tablet-up') {
        margin-top: rem(60);
      }

      @include query('laptop-up') {
        margin-top: rem(75);
      }

      @include query('desktop') {
        margin-top: rem(100);
      }
    }
  }

  &__slide {
    display: flex;
    box-sizing: border-box;

    @include query('mobile') {
      flex-direction: column;
      min-height: rem(161);
    }

    @include query('tablet-up') {
      min-height: rem(207);
    }

    @include query('laptop-up') {
      min-height: rem(318);
    }

    @include query('desktop') {
      min-height: rem(383);
    }

    &-content {
      width: percentage(1/2);
      position: relative;
      align-self: center;

      @include query('mobile') {
        width: 100%;
        margin-top: rem(55);
        padding-left: rem(36);
      }

      @include query('tablet-up') {
        padding-top: rem(34);
      }

      @include query('laptop-up') {
        padding-top: rem(43);
      }

      @include query('desktop') {
        padding-top: rem(55);
      }

      &::before {
        content: '';
        display: block;
        background-image: image('quote.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;

        @include query('mobile') {
          width: rem(20);
          height: rem(16);
        }

        @include query('tablet-up') {
          width: rem(20);
          height: rem(16);
        }

        @include query('laptop-up') {
          width: rem(23);
          height: rem(18);
        }

        @include query('desktop') {
          width: rem(33);
          height: rem(26);
        }
      }

      p {
        @include query('mobile') {
          @include font-size('supporter-testimonial-mobile');
        }

        @include query('tablet-up') {
          @include font-size('supporter-testimonial-tablet');
        }

        @include query('laptop-up') {
          @include font-size('supporter-testimonial-laptop');
        }

        @include query('desktop-small-up') {
          @include font-size('supporter-testimonial-desktop');
        }

        a {
          @extend %link-reset;

          @include transition(opacity, 350ms);

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &__image-wrapper {
    width: percentage(1/2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(70);

    @include query('mobile') {
      width: 100%;
      margin-right: 0;
      height: rem(161);
    }

    @include query('tablet-up') {
      min-height: rem(190);
    }

    @include query('laptop-up') {
      min-height: rem(292);
    }

    @include query('desktop') {
      min-height: rem(352);
    }

    &::before {
      content: '';
      display: block;
      background-image: image('brush-stroke.svg');
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      z-index: 1;
      background-size: contain;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;

      @include query('mobile') {
        background-position: center;
      }
    }
  }

  &__image {
    z-index: 100;
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: auto;
    border-radius: 100%;
    overflow: hidden;

    @include query('mobile') {
      max-width: rem(150);
      max-height: rem(150);
      margin-top: rem(12);
    }

    @include query('tablet-up') {
      max-width: rem(190);
      max-height: rem(190);
      margin-top: rem(16);
    }

    @include query('laptop-up') {
      max-width: rem(292);
      max-height: rem(292);
      margin-top: rem(25);
    }

    @include query('desktop') {
      max-width: rem(352);
      max-height: rem(352);
      margin-top: rem(30);
    }
  }

  &__name {
    @extend %link-reset;

    color: color('primary');
    text-transform: uppercase;
    letter-spacing: 0.2em;
    cursor: pointer;

    @include transition(opacity, 350ms);

    @include query('mobile') {
      @include font-size('supporter-testimonial-name-mobile');

      margin-top: rem(23);
    }

    @include query('tablet-up') {
      @include font-size('supporter-testimonial-name-tablet');

      margin-top: rem(18);
    }

    @include query('laptop-up') {
      @include font-size('supporter-testimonial-name-laptop');

      margin-top: rem(25);
    }

    @include query('desktop-small-up') {
      @include font-size('supporter-testimonial-name-desktop');

      margin-top: rem(30);
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__nav-prev,
  &__nav-next {
    color: color('primary');
    width: rem(14);
    height: rem(28);

    @include query('mobile') {
      top: 25%;
    }

    &::after {
      font-size: rem(24);
    }
  }

  &__nav-prev {
    left: 0;
  }

  &__nav-next {
    right: 0;
  }

  &__action {
    text-align: center;

    @include query('mobile') {
      margin-top: rem(68);
    }

    @include query('tablet-up') {
      margin-top: rem(85);
    }

    @include query('laptop-up') {
      margin-top: rem(88);
    }

    @include query('desktop') {
      margin-top: rem(104);
    }
  }

  &__button {
    @include query('mobile') {
      @include font-size('supporter-testimonial-button-mobile');
    }
  }

  .swiper-wrapper {
    @include query('tablet-up') {
      align-items: center;
    }
  }
}
