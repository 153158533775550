.wp-cto-footnote {
  &__text {
    p {
      color: color('primary');

      @include font-stack($font-primary);

      @include query('mobile') {
        @include font-size('note-mobile');
      }

      @include query('tablet') {
        @include font-size('note-tablet');
      }

      @include query('laptop') {
        @include font-size('note-laptop');
      }

      @include query('desktop') {
        @include font-size('note-desktop');
      }
    }

    a {
      text-decoration: none;
      font-weight: 600;
      color: color('primary');
    }
  }
}
