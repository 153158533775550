.wp-cto-video-icons {
  @include container();

  @include query('desktop') {
    padding-top: rem(150);
    padding-bottom: rem(110);
  }

  @include query('laptop') {
    padding-top: rem(75);
    padding-bottom: rem(110);
  }

  @include query('tablet') {
    padding-top: rem(60);
    padding-bottom: rem(80);
  }

  @include query('mobile') {
    padding-top: rem(50);
    padding-bottom: rem(60);
  }

  @include query('desktop-small') {
    padding-top: rem(74);
    padding-bottom: rem(100);
  }

  &__wrapper {
    @include row();

    &--main {
      @include query('tablet-up') {
        align-items: center;
      }

      @include query('mobile') {
        flex-direction: column-reverse;
      }
    }
  }

  &__holder {
    &--video {
      @include query('desktop') {
        @include column(6);
      }

      @include query('laptop') {
        @include column(5, 'laptop');
      }

      @include query('tablet') {
        @include column(5.5, 'tablet');
      }

      @include query('mobile') {
        @include column(12, 'mobile');
      }

      @include query('tablet-up') {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }

    &--headline {
      @include query('tablet-up') {
        @include column(5.5);
        @include offset(0.5);
      }

      @include query('laptop') {
        @include column(6.5, 'laptop');
        @include offset(0.5);
      }

      @include query('tablet') {
        @include column(6, 'tablet');
        @include offset(0.5);
      }

      @include query('mobile') {
        @include column(12, 'mobile');
      }
    }

    &--icons {
      @include column(10);
      @include offset(1);

      @include query('desktop') {
        margin-top: rem(180);
      }

      @include query('laptop') {
        margin-top: rem(135);

        @include column(10, 'laptop');
      }

      @include query('tablet') {
        margin-top: rem(100);

        @include column(10, 'tablet');
      }

      @include query('mobile') {
        margin-top: rem(140);
        padding: 0;

        @include column(10, 'mobile');
      }
    }

    &--button {
      @include column(12);

      display: flex;
      align-items: center;
      justify-content: center;

      @include query('desktop') {
        margin-top: rem(110);
      }

      @include query('laptop') {
        margin-top: rem(80);
      }

      @include query('tablet') {
        margin-top: rem(70);
      }

      @include query('mobile') {
        margin-top: rem(60);
      }
    }
  }

  &__headline {
    color: color('action');

    @include font-stack($font-secondary);

    .is-page-green & {
      color: color('action-torquoise');
    }

    @include query('laptop') {
      max-width: rem(420);
      padding: rem(20) 0;

      @include font-size('heading-large-laptop');
    }

    @include query('tablet') {
      max-width: rem(350);
      padding: rem(20) 0;

      @include font-size('heading-large-tablet');
    }

    @include query('mobile') {
      padding-bottom: rem(40);
      max-width: rem(280);

      @include font-size('heading-large-mobile');
    }

    @include query('desktop-small-up') {
      max-width: rem(590);
      padding: rem(50) 0;

      @include font-size('heading-large-desktop');
    }

    &:first-child {
      margin-bottom: 0;
    }
  }

  &__video-container {
    position: relative;

    @include query('tablet-up') {
      height: 100%;
      width: 100%;
    }
  }

  &__decoration {
    position: absolute;
    height: 100%;
    width: 100%;
    left: -23%;
    bottom: -50%;

    @include query('mobile') {
      height: 80%;
      left: -30%;
      bottom: -40%;
    }
  }

  &__video {
    position: relative;
    border-radius: rem(8);
    overflow: hidden;
  }
}
