.error-alert {
  display: flex;
  max-width: 400px;
  width: 100%;
  pointer-events: initial;
  background-color: #F9E3D4;
  padding: 10px 15px;
  border-radius: 6px;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

  &__icon {
    display: block;
    width: 13px;
    height: 13px;
  }

  &__message {
    margin-left: 8px;
    color: rgb(185, 28, 28);
    font-size: 15px;
  }

  &__action {
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    border: none;
    font-weight: 700;
    background: none;
    display: inline-block;
    color: rgb(185, 28, 28);
  }
}
