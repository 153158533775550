.wp-cto-error-page {
  min-height: 100vh;
  background-color: color('secondary');

  @include query('mobile') {
    padding-top: rem(100);
    padding-bottom: rem(35);
  }

  @include query('tablet') {
    padding-top: rem(120);
    padding-bottom: rem(55);
  }

  @include query('laptop') {
    padding-top: rem(140);
    padding-bottom: rem(80);
  }

  @include query('desktop') {
    padding-top: rem(160);
    padding-bottom: rem(100);
  }

  &__wrapper {
    @include container();
  }

  &__inner {
    @include row();
  }

  &__holder {
    position: relative;

    &--image {
      //
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include query('desktop') {
        @include column(8);
        @include offset(2);
      }

      @include query('laptop') {
        @include column(8, 'laptop');
        @include offset(2);
      }

      @include query('tablet') {
        @include column(8, 'tablet');
        @include offset(2);
      }

      @include query('mobile') {
        @include column(10, 'mobile');
        @include offset(1);
      }
    }
  }

  &__stroke-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 10%;
  }

  &__stroke {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }

  &__code {
    position: relative;
    padding: rem(100) rem(50);

    @include query('mobile') {
      padding: rem(50) rem(20);
    }
  }

  &__title {
    color: color('white');
    margin: 0 auto;
    text-align: center;

    @include font-stack($font-secondary);

    @include query('mobile') {
      margin-bottom: rem(30);

      @include font-size('heading-mobile');
    }

    @include query('tablet-up') {
      margin-bottom: rem(50);
    }

    @include query('tablet') {
      @include font-size('heading-tablet');
    }

    @include query('laptop') {
      @include font-size('heading-laptop');
    }

    @include query('desktop') {
      @include font-size('heading-desktop');
    }
  }

  &__msg {
    max-width: rem(863);
    margin: 0 auto;
    color: color('white');
    text-align: center;

    @include font-stack($font-primary);

    @include query('desktop') {
      @include font-size('subheading-small-desktop');
    }

    @include query('laptop') {
      @include font-size('subheading-small-laptop');
    }

    @include query('tablet') {
      @include font-size('subheading-small-tablet');
    }

    @include query('mobile') {
      @include font-size('subheading-small-mobile');
    }
  }

  &__button {
    margin-top: rem(50);
  }
}
