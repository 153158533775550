@use '../abstracts/mixins';

.embed {
  display: block;
  max-width: 100%;
  margin-top: 20px;

  &__title {
    font-weight: 700;
  }

  &__content-wrapper {
    background-color: #f8f8f8;
    padding: 40px 20px 20px;
    margin-top: 5px;
    position: relative;
  }

  &__button {
    @include mixins.button-reset;

    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    color: black;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 6px;
    transition: background-color 200ms ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &__content {
    font-family: monospace;
    line-height: 140%;
  }
}
