.wp-cto-accordion {
  $this: &;

  &__headline {
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: color('action');

    @include font-stack($font-primary);

    .is-page-green & {
      color: color('action-turquoise');
    }

    @include query('mobile') {
      padding-bottom: rem(20);

      @include font-size('subheading-mobile');
    }

    @include query('tablet') {
      padding-bottom: rem(24);

      @include font-size('subheading-tablet');
    }

    @include query('laptop-up') {
      padding-bottom: rem(30);
    }

    @include query('laptop') {
      @include font-size('subheading-laptop');
    }

    @include query('desktop') {
      @include font-size('subheading-desktop');
    }
  }

  &__holder {
    border-top: 1px solid color('primary');
  }

  &__accordion {
    border-bottom: 1px solid color('primary');
  }

  &__button {
    @extend %button-reset;

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0;
    background-color: transparent;

    @include query('mobile') {
      padding-right: rem(30);
    }

    @include query('tablet') {
      padding-right: rem(60);
    }

    @include query('laptop') {
      padding-right: rem(70);
    }

    @include query('desktop') {
      padding-right: rem(110);
    }
  }

  &__title {
    color: color('primary');
    text-align: left;

    @include font-stack($font-primary);

    @include query('mobile') {
      padding: rem(21) 0;

      @include font-size('subheading-small-mobile');
    }

    @include query('tablet') {
      padding: rem(20) 0;

      @include font-size('subheading-small-tablet');
    }

    @include query('laptop') {
      padding: rem(27) 0;

      @include font-size('subheading-small-laptop');
    }

    @include query('desktop') {
      padding: rem(30) 0;

      @include font-size('subheading-small-desktop');
    }
  }

  &__sign {
    position: absolute;
    right: 0;
    border: 2px solid color('action');
    border-radius: 50%;

    .is-page-green & {
      border: 2px solid color('action-turquoise');
    }

    @include query('mobile') {
      height: rem(25);
      width: rem(25);
    }

    @include query('tablet') {
      height: rem(30);
      width: rem(30);
    }

    @include query('tablet-down') {
      border-width: 1px;
    }

    @include query('laptop') {
      height: rem(35);
      width: rem(35);
    }

    @include query('desktop') {
      height: rem(40);
      width: rem(40);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      transform: translate(-50%, -50%) rotate(90deg);
      background-color: color('action');
      opacity: 1;

      @include transition(opacity);

      .is-page-green & {
        background-color: color('action-turquoise');
      }

      @include query('mobile') {
        width: rem(7);
      }

      @include query('tablet') {
        width: rem(8);
      }

      @include query('tablet-down') {
        height: 1px;
      }

      @include query('laptop') {
        width: rem(10);
      }

      @include query('desktop') {
        width: rem(12);
      }

      .is-open-accordion & {
        opacity: 0;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      transform: translate(-50%, -50%);
      background-color: color('action');

      .is-page-green & {
        background-color: color('action-turquoise');
      }

      @include query('mobile') {
        width: rem(7);
      }

      @include query('tablet') {
        width: rem(8);
      }

      @include query('tablet-down') {
        height: 1px;
      }

      @include query('laptop') {
        width: rem(10);
      }

      @include query('desktop') {
        width: rem(12);
      }
    }
  }

  &__accordion-body {
    max-height: 0;
    height: 0;
    overflow: hidden;

    @include transition();

    #{$this}__accordion.is-open-accordion & {
      max-height: 100%;
    }
  }

  &__content {
    color: color('primary');

    @include font-stack($font-primary);

    @include query('mobile') {
      @include font-size('paragraph-mobile');
    }

    @include query('tablet') {
      @include font-size('paragraph-tablet');
    }

    @include query('laptop') {
      @include font-size('paragraph-laptop');
    }

    @include query('desktop') {
      @include font-size('paragraph-desktop');
    }

    @include query('mobile') {
      padding-bottom: rem(21);
      padding-right: rem(30);
    }

    @include query('tablet') {
      padding-bottom: rem(20);
      padding-right: rem(60);
    }

    @include query('laptop') {
      padding-bottom: rem(27);
      padding-right: rem(70);
    }

    @include query('desktop') {
      padding-bottom: rem(30);
      padding-right: rem(110);
    }
  }
}
