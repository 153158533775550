.wp-cto-hero {
  $this: &;

  position: relative;
  overflow: hidden;

  &--secondary {
    background-color: color('secondary-torquoise');
  }

  &__background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: color('secondary');

    &--desktop {
      object-fit: cover;
      object-position: center;

      @include query('mobile') {
        display: none;
      }
    }

    &--mobile {
      object-fit: cover;
      object-position: bottom;

      @include query('tablet-up') {
        display: none;
      }
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include container();

    @include query('mobile') {
      padding-top: rem(130);
      padding-bottom: rem(35);
      align-items: center;
      flex-direction: column;
    }

    @include query('tablet') {
      padding-top: rem(180);
      padding-bottom: rem(55);
    }

    @include query('laptop') {
      padding-top: rem(190);
      padding-bottom: rem(80);
    }

    @include query('desktop-small') {
      padding-top: rem(270);
      padding-bottom: rem(80);
    }

    @include query('desktop') {
      padding-top: rem(280);
      padding-bottom: rem(100);
    }

    #{$this}--extended & {
      @include query('mobile') {
        padding-top: rem(130);
        padding-bottom: rem(80);
      }

      @include query('tablet') {
        padding-top: rem(180);
        padding-bottom: rem(80);
      }

      @include query('laptop') {
        padding-top: rem(190);
        padding-bottom: rem(80);
      }

      @include query('desktop') {
        padding-top: rem(280);
        padding-bottom: rem(100);
      }
    }

    #{$this}--secondary & {
      @include query('mobile') {
        padding-top: rem(122);
        padding-bottom: rem(60);
      }

      @include query('tablet') {
        padding-top: rem(136);
      }

      @include query('laptop') {
        padding-top: rem(164);
        padding-bottom: rem(70);
      }

      @include query('desktop') {
        padding-top: rem(226);
      }
    }
  }

  &__holder {
    &--text {
      display: flex;
      flex-direction: column;

      @include query('mobile') {
        align-items: center;
        margin-bottom: rem(60);
      }

      @include query('tablet-up') {
        justify-content: center;

        #{$this}--extended & {
          padding: rem(30) rem(20) rem(30) 0;
        }
      }

      #{$this}--secondary & {
        padding-top: 0;
        padding-bottom: 0;

        @include query('mobile') {
          padding-top: rem(20);
        }

        @include query('tablet') {
          width: 65%;
          justify-content: flex-start;
        }

        @include query('laptop') {
          width: 68%;
        }

        @include query('desktop-small') {
          width: 65%;
        }

        @include query('desktop') {
          width: 65%;
        }
      }
    }

    &--image {
      @include query('mobile') {
        display: flex;
        justify-content: center;
      }

      #{$this}--secondary & {
        @include query('mobile') {
          margin-bottom: rem(-96);
        }

        @include query('tablet') {
          width: 35%;
        }

        @include query('tablet-up') {
          margin-left: auto;
          margin-right: rem(-10);
        }

        @include query('laptop') {
          width: calc(33% + #{rem(10)});
          margin-top: rem(20);
          margin-bottom: rem(-280);
        }

        @include query('desktop-small') {
          width: calc(34% + #{rem(10)});
          margin-top: rem(30);
        }

        @include query('desktop') {
          width: calc(33% + #{rem(10)});
          margin-top: rem(30);
          margin-bottom: rem(-280);
        }
      }
    }

    &--laptop {
      #{$this}__subheadline,
      #{$this}__button-container {
        @include query('mobile') {
          display: none;
        }
      }
    }

    &--tablet {
      position: relative;
      width: 100%;
      padding-top: rem(50);
      z-index: 0;

      @include query('tablet-up') {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        bottom: -60px;
        right: -20px;
        background-color: color('action-turquoise');
        z-index: -1;
      }

      #{$this}--secondary & {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  &__headline {
    margin: 0;
    color: color('white');

    @include font-stack($font-secondary);

    @include query('mobile') {
      text-align: center;

      @include font-size('page-headline-mobile');
    }

    @include query('tablet') {
      @include font-size('page-headline-tablet');

      max-width: rem(440);
    }

    @include query('laptop') {
      @include font-size('page-headline-laptop');
    }

    @include query('desktop-small') {
      @include font-size('page-headline-desktop-small');
    }

    @include query('desktop') {
      @include font-size('page-headline-desktop');
    }

    #{$this}--secondary & {
      @include query('mobile') {
        @include font-size('page-secondary-headline-mobile-xs');

        max-width: rem(280);
      }

      @include query('tablet') {
        @include font-size('page-secondary-headline-mobile');
      }

      @include query('laptop') {
        @include font-size('page-secondary-headline-tablet');

        max-width: rem(580);
      }

      @include query('desktop-small') {
        @include font-size('page-secondary-headline-laptop');

        max-width: rem(620);
      }

      @include query('desktop') {
        @include font-size('page-secondary-headline-desktop');

        max-width: rem(800);
      }
    }
  }

  &__subheadline {
    &--paragraph,
    &--content p {
      margin: 0;
      color: color('white');

      @include font-stack($font-primary);

      @include query('mobile') {
        max-width: rem(220);
        text-align: center;
      }

      @include query('tablet') {
        max-width: rem(240);
      }

      @include query('tablet-down') {
        margin-top: rem(30);
      }

      @include query('laptop') {
        margin-top: rem(40);
      }

      @include query('laptop-down') {
        @include font-size('title-tablet');
      }

      @include query('desktop-small-up') {
        margin-top: rem(50);

        @include font-size('title-desktop');
      }

      strong {
        font-weight: 700;
      }

      #{$this}--secondary & {
        @include query('mobile') {
          max-width: rem(400);

          @include font-size('title-description-mobile');
        }

        @include query('tablet') {
          max-width: rem(440);

          @include font-size('title-description-tablet');
        }

        @include query('laptop') {
          max-width: rem(630);

          @include font-size('title-description-laptop');
        }

        @include query('desktop-small-up') {
          max-width: rem(800);

          @include font-size('title-description-desktop');
        }

        @include query('desktop') {
          max-width: rem(800);
        }
      }
    }
  }

  &__image-container {
    display: flex;
    position: relative;
    cursor: pointer;
    opacity: 1;

    @include transition();

    @include query('mobile') {
      margin-right: rem(10);
    }

    @include query('tablet-up') {
      margin-right: rem(20);
    }

    @include hover-focus {
      transform: scale(1.05);
      opacity: 0.9;

      #{$this}--secondary & {
        transform: none;
        opacity: 1;
        cursor: default;
      }
    }

    #{$this}--secondary & {
      display: flex;
      align-items: flex-end;
      height: 100%;
      margin-right: 0;
      justify-content: flex-end;
      max-width: 100%;
    }
  }

  &__image {
    height: 100%;
    width: auto;
    max-width: unset;
    object-fit: contain;

    @include query('mobile') {
      max-height: 60vh;
      max-width: 100%;
    }

    @include query('tablet') {
      max-height: rem(600);
    }

    @include query('laptop') {
      max-height: rem(600);
    }

    #{$this}--secondary & {
      max-width: 100%;
      max-height: 100%;
      object-position: top;
    }
  }

  &__card-image {
    position: relative;

    @include query('mobile') {
      max-height: 60vh;
    }

    @include query('tablet') {
      max-height: rem(340);
    }

    @include query('laptop') {
      max-height: rem(420);
    }

    @include query('desktop-small') {
      max-height: unset;
    }
  }

  &__image-drop {
    position: absolute;
    height: 100%;
    width: 100%;
    right: rem(-20);
    bottom: rem(-20);
    border-radius: rem(6);
    background-color: color('white');
    opacity: 0.5;

    @include query('mobile') {
      right: rem(-10);
      bottom: rem(-10);
    }

    @include query('tablet-up') {
      right: rem(-20);
      bottom: rem(-20);
    }
  }

  &__button-container {
    @include query('tablet-down') {
      margin-top: rem(30);
    }

    @include query('laptop') {
      margin-top: rem(40);
    }

    @include query('desktop-small') {
      margin-top: rem(47);
    }

    @include query('desktop') {
      margin-top: rem(50);
    }
  }
}
