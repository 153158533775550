@use '../variables/fonts';
@use '../abstracts/mixins';

.notifications {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: initial;
  z-index: 100;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  overflow: hidden;
}

.notification {
  background-color: #fef2f2;
  border-radius: 6px;
  padding: 20px;
  color: rgb(185, 28, 28);
  font-weight: 700;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
}
