@use '../variables/fonts';
@use '../variables/colors';
@use '../abstracts/mixins';
@use '../modules/media';

.media-card {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: white;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    transition: opacity 200ms ease;
    will-change: opacity;

    &.is-hidden {
      opacity: 0;
      pointer-events: none !important;

      & * {
        pointer-events: none !important;
      }
    }
  }

  &__content-wrapper {
    margin-top: auto;
    padding: 0 25px 25px;
    display: grid;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      min-height: 510px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      z-index: -1;
    }
  }

  &__content {
    margin-top: auto;
    pointer-events: initial;
  }

  &__user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
  }

  &__user-avatar {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 3px solid #fff;
    margin-right: 10px;
  }

  &__user-name {
    font-size: 16px;
    line-height: 40px;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
  }

  &__description {
    font-size: 3vh;
    line-height: 4vh;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    max-width: 100%;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color: #fff;
  }

  &__media {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__slider {
    height: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
    }
  }

  &__slider-image {
    display: block;

    max-width: 100%;
    max-height: 100%;

    &.is-full {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 11px;
    margin-top: 20px;

    &--white .media-card__action {
      border-color: black !important;
      color: black !important;
    }
  }

  &__heart-button {
    @include mixins.button-reset;

    margin: 55px auto auto;
    pointer-events: initial;
  }

  &__play-button {
    @include mixins.button-reset;

    margin: 0 auto;
    pointer-events: initial;
  }

  &__action {
    @include mixins.button-reset;
    @include mixins.font(fonts.$secondary);

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 100px;
    height: 50px;
    padding: 10px;
    background: none;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    line-height: 55px;
    font-weight: 700;
  }

  &__action-icon {
    margin-right: 5px;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
  }

  &__modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 200ms ease;
    opacity: 0;
    pointer-events: none;
    will-change: opacity;

    .is-opened & {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__modal-header {
    display: flex;
    width: 100%;
    padding: 0 15px;
  }

  &__modal-avatar {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 25px auto 13px;
  }

  &__modal-text-wrapper {
    overflow: auto;
    padding: 0 15px;
    height: 400px;
    position: relative;
  }

  &__modal-scroll-wrapper {
    position: relative;
  }

  &__modal-scroll-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    z-index: 10;
    pointer-events: none;
  }

  &__pagination-wrapper {
    margin-bottom: 20px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin: 0 auto;
  }

  &__pagination-bullet {
    pointer-events: none;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    will-change: opacity;
    transition: opacity 200ms ease, transform 300ms ease;
    opacity: 0.3;
    background-color: #ffffff;

    &.is-active {
      opacity: 1;
    }

    &.is-hidden {
      display: none;
    }

    &.is-next {
      transform: scale(0.8);
    }

    & + & {
      margin-left: 6px;
    }
  }

  &__modal-scroll-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 10;
    pointer-events: none;
  }

  &__modal-text {
    font-size: 20px;
    line-height: 32px;
    padding: 20px 0;
  }

  &__modal-title {
    @include mixins.font(fonts.$secondary);

    text-align: center;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }

  &__social-wrapper {
    position: absolute;
    bottom: calc(100% - 15px);
    right: 0;
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-top: 15px;
  }

  &__social-link {
    & + & {
      margin-left: 10px;
    }
  }

  &__modal-close {
    @include mixins.button-reset;

    width: 21px;
    margin-left: auto;
  }

  &__modal-body {
    background-color: #fff;
    pointer-events: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 30px 20px 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    will-change: transform;
    transform: translateY(100%);
    transition: transform 200ms ease;
    max-height: 85vh;

    .is-opened & {
      transform: translateY(0);
      pointer-events: initial;
    }
  }

  &__overlay-pagination {
    position: absolute;
    width: 100%;
    pointer-events: none;
    left: 0;
    bottom: 40px;
    will-change: opacity;
    transition: opacity 200ms ease;
    z-index: 1;

    &.is-hidden {
      opacity: 0;
    }
  }

  &__modal-share-wrapper {
    padding: 0 15px;
    margin-top: 25px;
  }

  &__share-link-wrapper {
    & + & {
      margin-top: 20px;
    }
  }

  &__share-link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  &__share-link-text {
    @include mixins.font(fonts.$secondary);

    font-size: 18px;
    line-height: 26px;
    margin-left: 15px;
  }

  &__matcher {
    color: white;
    font-size: 13px;
    line-height: 18px;
    margin-top: 20px;
    text-align: center;
  }

  &__image-preview {
    @include mixins.button-reset;

    position: absolute;
    bottom: 10px;
    right: 25px;
    //transform: translateY(-50%);
    pointer-events: initial !important;
    z-index: 20;
  }

  &__payment-wrapper {
    margin-top: 40px;

    &--no-spacing {
      margin-top: 0;
    }
  }

  &__payment-actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 7px;
  }

  &__payment-action {
    @include mixins.button-reset;

    border-radius: 100px;
    border: 1px solid colors.$primary;
    font-weight: 700;
    color: colors.$primary;
    font-size: 14px;
    line-height: 50px;
    height: 50px;

    &.is-selected {
      background-color: colors.$primary;
      color: #fff;
    }
  }

  &__payment-button {
    @include mixins.button-reset;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: colors.$primary;
    color: white;
    border-radius: 100px;
    margin-top: 15px;


    &[disabled] {
      pointer-events: none !important;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  &__payment-button-text {
    @include mixins.font(fonts.$secondary);

    font-size: 16px;
    line-height: 50px;
    font-weight: 700;
    margin-left: 5px;
  }

  &__custom-payment-wrapper {
    position: relative;
  }

  &__payment-prefix {
    position: absolute;
    font-weight: 700;
    color: colors.$primary;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    top: 0;
    left: 24px;
  }

  &__payment-input {
    border-radius: 100px;
    border: 1px solid colors.$primary;
    font-weight: 700;
    color: colors.$primary;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding: 0 90px 0 32px;
    display: block;
    width: 100%;
  }

  &__custom-cancel {
    @include mixins.button-reset;

    position: absolute;
    font-size: 13px;
    line-height: 50px;
    padding: 0 5px;
    color: colors.$danger;
    top: 0;
    right: 19px;
    font-weight: 700;
  }
}

.media-card-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1000;
  will-change: opacity;
  opacity: 0;
  transition: opacity 200ms ease;
  pointer-events: none;

  &.is-opened {
    opacity: 1;
    pointer-events: initial;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .is-opened & {
      pointer-events: initial;
    }
  }

  &__close {
    @include mixins.button-reset;

    position: absolute;
    top: 40px;
    right: 20px;
    z-index: 10;
  }
}

.media-card-image {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 9999;
  will-change: opacity;
  opacity: 0;
  transition: opacity 200ms ease;
  pointer-events: none;

  &.is-opened {
    opacity: 1;
    pointer-events: initial;
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;

    .is-opened & {
      pointer-events: initial;
    }
  }

  &__close {
    @include mixins.button-reset;

    position: absolute;
    top: 40px;
    right: 20px;
    z-index: 10;
    pointer-events: none;

    &.is-opened {
      pointer-events: initial;
    }
  }
}
