@use '../variables/colors';
@use '../modules/media';

.card-page {
  background-color: #faf7fb;
  min-height: 100vh;

  @include media.query('tablet-down') {
    padding: 15px 5px 5px;
    margin-top: -63px;
  }

  @include media.query('tablet-up') {
    padding: 50px 5px 5px;
    margin-top: -72px;
  }

  &__actions {
    max-width: 340px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 15px;
  }

  &__card {
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    &--white * {
      color: white !important;
    }
  }

  &__footer-links {
    display: flex;
    margin-top: 20px;

    @include media.query('tablet-down') {
      flex-direction: column;
    }
  }

  &__footer-link {
    text-decoration: none;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: #989898;
    text-align: center;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    & + & {
      @include media.query('tablet-down') {
        margin-top: 20px;
      }

      @include media.query('tablet-up') {
        margin-left: 40px;
      }
    }

    &:not(.card-page__footer-link--nested):hover {
      text-decoration: underline;
    }
  }

  &__footer-copyright {
    font-size: 13px;
    line-height: 20px;
    color: #989898;
    text-align: center;
    margin-top: 15px;
  }

  &__media-button {
    background: none;
    color: black;
    border: 1px solid black;
    border-radius: 100px;
    font-size: 14px;
    line-height: 40px;
    font-weight: 700;
    cursor: pointer;
    padding: 0 20px;
    transition: color 200ms ease, background-color 200ms ease;

    &:hover {
      background-color: black;
      color: white;
    }
  }

  &__settings-dropdown-wrapper {
    position: relative;
    z-index: 10;
  }

  &__settings-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    transition: opacity 200ms ease;

    &:hover {
      opacity: 0.7;
    }
  }

  &__settings-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background: white;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: 10;
    width: max-content;
    overflow: hidden;
  }

  &__dropdown-button {
    display: block;
    width: 100%;
    border: none;
    background: none;
    padding: 25px;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    transition: background-color 200ms ease;
    text-align: left;

    &:hover {
      background-color: rgba(#000, 0.05);
    }

    & + & {
      border-top: 1px solid #e6e6e6;
    }
  }
}
