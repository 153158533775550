/* stylelint-disable */

@mixin font-stack($fonts...) {
  font-family: make-font-stack($fonts...);
}

@mixin hover() {
  &:hover { @content; }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin query($name) {
  @include mappy-query($name) {
    @content;
  }
}

@mixin font-size($name) {
  $list: map-get($font-sizes, $name);

  $font-size: nth($list, 1);
  $line-height: nth($list, 2);
  $font-weight: nth($list, 3);

  font-size: rem($font-size);
  line-height: rem($line-height);

  @if ($font-weight) {
    font-weight: $font-weight;
  } @else {
    font-weight: normal;
  }
}

/**
 * Add transition to element
 */
@mixin transition($transition: all, $duration: 0.2s, $type: ease) {
  -webkit-transition: $transition $duration $type;
  transition: $transition $duration $type;
}

/* stylelint-enable */
