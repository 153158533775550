.wp-cto-card-slider {
  $this: &;

  &__wrapper {
    background-color: color('pale-gray');

    @include query('mobile') {
      padding: rem(50) 0 rem(60) 0;
    }

    @include query('tablet-up') {
      padding: rem(60) 0 rem(80) 0;
    }

    @include query('laptop-up') {
      padding: rem(75) 0 rem(100) 0;
    }

    @include query('desktop') {
      padding: rem(100) 0;
    }
  }

  &__title {
    @include font-stack($font-secondary);

    text-align: center;
    color: color('primary');
    margin: 0 auto;

    @include query('mobile') {
      @include font-size('card-slider-title-mobile');
    }

    @include query('tablet-up') {
      @include font-size('card-slider-title-tablet');
    }

    @include query('laptop-up') {
      @include font-size('card-slider-title-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('card-slider-title-desktop');
    }
  }

  &__inner {
    @include query('tablet-up') {
      @include container();
    }
  }

  &__container {
    @include query('mobile') {
      width: 100%;
    }

    @include query('tablet-up') {
      @include offset(1);
      @include column(10);
    }
  }

  &__holder {
    position: relative;

    & + & {
      @include query('mobile') {
        margin-top: rem(35);
      }

      @include query('tablet-up') {
        margin-top: rem(60);
      }

      @include query('laptop-up') {
        margin-top: rem(75);
      }

      @include query('desktop') {
        margin-top: rem(100);
      }
    }

    &--title {
      @include query('mobile') {
        @include container();
      }
    }
  }

  &__slide {
    width: auto;

    @include query('mobile') {
      width: 100%;
      max-width: rem(180);

      &:not(.swiper-slide-active) {
        #{$this}__card-wrapper {
          opacity: 0.5;
        }
      }
    }

    @include query('mobile-extra') {
      width: 100%;
      max-width: rem(180);
    }
  }

  &__card-wrapper {
    width: 100%;
    display: block;
    cursor: pointer;

    @include transition(opacity, 250ms);

    @include query('mobile') {
      max-width: rem(180);
      margin: 0 auto;
    }

    @include query('tablet-up') {
      max-width: rem(164);
    }

    @include query('laptop-up') {
      max-width: rem(216);
    }

    @include query('desktop') {
      max-width: rem(307);
    }
  }

  &__image {
    width: auto;
    border-radius: rem(6);

    @include query('mobile') {
      width: 100%;
    }
  }

  &__nav-prev,
  &__nav-next {
    color: color('primary');
    width: rem(14);
    height: rem(28);

    @include query('mobile') {
      display: none;
    }

    &::after {
      font-size: rem(24);
    }
  }

  &__nav-prev {
    left: 0;
  }

  &__nav-next {
    right: 0;
  }

  &__pagination {
    bottom: rem(-30) !important;

    .swiper-pagination-bullet {
      background-color: color('gray-lightest');
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: color('gray-light');
      }
    }

    @include query('tablet-up') {
      display: none;
    }
  }
}
