.wp-cto-block-spacing {
  @include query('desktop') {
    margin-top: rem(80);
    margin-bottom: rem(80);
  }

  @include query('laptop') {
    margin-top: rem(50);
    margin-bottom: rem(50);
  }

  @include query('tablet') {
    margin-top: rem(40);
    margin-bottom: rem(40);
  }

  @include query('mobile') {
    margin-top: rem(30);
    margin-bottom: rem(30);
  }

  &:first-child {
    margin-top: 0;
  }
}
