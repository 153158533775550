@use '../variables/colors';
@use '../variables/radiuses';
@use '../modules/media';

.custom-cropper-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 10px 0;
  opacity: 0;
  pointer-events: none;

  &.is-opened {
    opacity: 1;
    pointer-events: initial;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 30px;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: white;
  }

  &__body {
    max-width: 400px;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  &__main {
    background: #5b5b5b;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
  }
}

.custom-cropper {
  width: 400px;
  height: 500px;
  background-color: #666;
  position: relative;
  overflow: hidden;
  cursor: move;

  &__grid {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  &__actions {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 200;
    display: flex;
    align-items: center;
  }

  &__action {
    border: none;
    border-radius: 100%;
    padding: 0;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;

    & + & {
      margin-left: 10px;
    }
  }

  &__image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__horizontal-grid-line {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    pointer-events: none;

    &:first-child {
      top: 33.333%;
    }

    &:nth-child(2) {
      top: 66.6666%;
    }
  }

  &__image {
    display: block;
    position: absolute;
  }

  &__vertical-grid-line {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: white;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    pointer-events: none;

    &:first-child {
      left: 33.333%;
    }

    &:nth-child(2) {
      left: 66.6666%;
    }
  }
}
