.wp-cto-arrow {
  position: relative;
  display: inline-block;
  width: rem(8);
  height: rem(8);
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    bottom: 45%;
    height: 2px;
    width: 10px;
    transform: rotate(135deg);
    background-color: color('action');

    .is-page-green & {
      background-color: color('action-turquoise');
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: calc(50% - 2px);
    bottom: 45%;
    height: 2px;
    width: 10px;
    transform: rotate(45deg);
    background-color: color('action');

    .is-page-green & {
      background-color: color('action-turquoise');
    }
  }
}
