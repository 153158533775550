.wp-cto-icon-list {
  &__wrapper {
    background-color: color('pale-gray');

    @include query('mobile') {
      padding-top: rem(60);
      padding-bottom: rem(60);
    }

    @include query('tablet') {
      padding-top: rem(55);
      padding-bottom: rem(55);
    }

    @include query('laptop') {
      padding-top: rem(75);
      padding-bottom: rem(75);
    }

    @include query('desktop-small-up') {
      padding-top: rem(100);
      padding-bottom: rem(100);
    }
  }

  &__inner {
    @include container();
  }

  &__holder {
    @include row();
  }

  &__item {
    @include query('mobile') {
      @include column(12);

      margin-top: 0;
      margin-bottom: 0;
    }

    @include query('tablet') {
      @include column(4);
    }

    @include query('laptop-up') {
      @include column(4);
    }

    @include query('tablet-up') {
      padding-right: 0;

      &:last-child {
        padding-right: rem(15);
      }
    }
  }

  &__item + &__item {
    @include query('mobile') {
      margin-top: rem(50);
    }
  }

  &__item-wrapper {
    @include query('tablet-up') {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding-left: rem(30);
      border-left: rem(1) solid rgba(color('primary'), 0.5);
    }

    @include query('tablet') {
      padding-left: rem(14);
    }

    @include query('laptop') {
      padding-left: rem(15);
    }

    @include query('desktop-small') {
      padding-left: rem(20);
    }

    @include query('desktop') {
      padding-left: rem(30);
    }
  }

  &__image {
    @include query('mobile') {
      margin-bottom: rem(16);
    }

    @include query('tablet') {
      margin-bottom: rem(22);
    }

    @include query('tablet-down') {
      max-width: rem(33);
    }

    @include query('laptop') {
      margin-bottom: rem(45);
      max-width: rem(45);
    }

    @include query('desktop-small-up') {
      margin-bottom: rem(75);
      max-width: rem(60);
    }
  }

  &__title {
    @include query('mobile') {
      @include font-size('icon-list-title-mobile');
    }

    @include query('tablet') {
      @include font-size('icon-list-title-tablet');
    }

    @include query('laptop') {
      @include font-size('icon-list-title-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('icon-list-title-desktop');
    }

    .is-page-green & {
      color: color('action-turquoise');
    }
  }

  &__description {
    @include query('mobile') {
      @include font-size('icon-list-description-mobile');
    }

    @include query('tablet') {
      @include font-size('icon-list-description-tablet');
    }

    @include query('laptop') {
      @include font-size('icon-list-description-tablet');
    }

    @include query('desktop-small') {
      @include font-size('icon-list-description-laptop');
    }

    @include query('desktop') {
      @include font-size('icon-list-description-desktop');
    }
  }

  &__action {
    @include column(12);

    display: flex;
    justify-content: center;

    @include query('mobile') {
      margin-top: rem(50);
    }

    @include query('tablet') {
      margin-top: rem(55);
    }

    @include query('laptop') {
      margin-top: rem(60);
    }

    @include query('desktop-small-up') {
      margin-top: rem(100);
    }
  }
}
