@use '../variables/fonts';
@use '../variables/colors';
@use '../abstracts/mixins';
@use '../modules/media';

.shared-card-page-background {
  box-shadow: 10px 0 20px rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    width: 30px;
    height: 100%;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.12) 50%, rgba(0,0,0,0.25) 100%);
  }
}

.shared-card-page {
  padding-top: 40px;
  //margin-top: -33px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  &__collection {
    display: grid;
    grid-template-columns: 72px 1fr;
    grid-gap: 20px;
    max-width: 335px;
    margin: 0 auto 20px;
  }

  &__footer {
    padding-bottom: 40px;
    color: inherit !important;
  }

  &__footer-links {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
  }

  &__footer-copyright {
    font-size: 13px;
    line-height: 20px;
    color: inherit;
    text-align: center;
    margin-top: 15px;
  }

  &__footer-link {
    text-decoration: none;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: inherit;
    text-align: center;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    & + & {
      margin-top: 20px;
    }

    &:not(.card-page__footer-link--nested):hover {
      text-decoration: underline;
    }
  }

  &__endorser {
    display: flex;
    align-items: center;
    max-width: 335px;
    margin: 0 auto 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(#fff, 0.1);
  }

  &__endorser-avatar {
    display: block;
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 15px;
  }

  &__endorser-text {
    color: inherit;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    a {
      color: inherit;
    }
  }

  &__logo {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 100%;
    object-position: center;
  }

  &__description {
    color: inherit;
    font-size: 16px;
    line-height: 20px;
  }

  &__description-matching {
    margin-top: 10px;
  }
}

.shared-card {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  max-width: 335px;
  margin: 0 auto;

  &__body {
    background-color: white;
    padding: 20px;
  }

  &__button-prev {
    display: block;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    border-bottom: 4px solid white;
    border-left: 4px solid white;
    position: absolute;
    top: calc(50% - 10px);
    left: 20px;
    transform: rotate(45deg) translateY(-50%);
    pointer-events: none;
    opacity: 0.9;
    cursor: pointer;
  }

  &__button-next {
    opacity: 0.9;
    display: block;
    width: 20px;
    height: 20px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    border-bottom: 4px solid white;
    border-right: 4px solid white;
    position: absolute;
    top: calc(50% - 10px);
    right: 20px;
    transform: rotate(-45deg) translateY(-50%);
    pointer-events: none;
  }

  &__button--clickable {
    pointer-events: initial;
    cursor: pointer;
  }

  &__title {
    @include mixins.font(fonts.$secondary);

    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: #000;
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding-top: 18px;
  }

  &__media {
    height: 420px;
    position: relative;
  }

  &__slider-wrapper {
    height: 420px;
    position: relative;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__slider {
    height: 100%;
  }

  &__slider-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__avatar-wrapper {
    position: relative;
    //padding-top: 10px;
  }

  &__avatar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 88px;
    height: 88px;
    border-radius: 100%;
    z-index: 10;
    border: 5px solid white;
    background: white;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    will-change: opacity;
    transition: opacity 200ms ease;
    z-index: 10;

    &.is-hidden {
      opacity: 0;

      & * {
        pointer-events: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 205px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__play-button {
    @include mixins.button-reset;

    position: absolute;
    top: calc(50% + 7px);
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    pointer-events: initial;
  }

  &__pagination-wrapper {
    position: absolute;
    bottom: 23px;
    left: 21px;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
