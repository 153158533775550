@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Bold.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Bold.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Bold.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Medium.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Medium.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Medium.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-MediumOblique.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-MediumOblique.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-MediumOblique.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-MediumOblique.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-BoldOblique.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-BoldOblique.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-BoldOblique.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-BoldOblique.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-HeavyOblique.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-HeavyOblique.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-HeavyOblique.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-HeavyOblique.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-HeavyOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Heavy.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Heavy.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Heavy.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-BookOblique.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-BookOblique.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-BookOblique.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-BookOblique.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-BookOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Book.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Book.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Book.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Book.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-LightOblique.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-LightOblique.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-LightOblique.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-LightOblique.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Std';
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Light.eot');
  src: url('../../assets/fonts/FuturaStd/FuturaStd-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Light.woff2') format('woff2'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Light.woff') format('woff'),
  url('../../assets/fonts/FuturaStd/FuturaStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Agrandir';
  src: url('../../assets/fonts/PPAgrandir/PPAgrandir-Regular.eot');
  src: url('../../assets/fonts/PPAgrandir/PPAgrandir-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/PPAgrandir/PPAgrandir-Regular.woff2') format('woff2'),
  url('../../assets/fonts/PPAgrandir/PPAgrandir-Regular.woff') format('woff'),
  url('../../assets/fonts/PPAgrandir/PPAgrandir-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

