.wp-cto-form {
  .nf-form-title {
    display: block !important;
    max-height: 999px;
    opacity: 1;
    overflow: hidden;

    @include transition();

    h3 {
      @include font-size('subheading-secondary-mobile');
      @include font-stack($font-primary);

      padding-bottom: rem(45);
      font-weight: 400;
      color: color('primary');

      @include query('tablet-down') {
        padding-right: rem(40);
      }

      @include query('laptop-up') {
        padding-right: rem(80);
      }
    }
  }

  .nf-form-fields-required,
  .ninja-forms-req-symbol,
  .nf-after-form-content .nf-error-msg {
    display: none;
  }

  .nf-error-msg {
    @include font-size('note-tablet');
  }

  .nf-field-label label {
    margin-bottom: rem(10);
    font-weight: 400 !important;
    color: color('gray-light');

    @include font-stack($font-primary);
    @include font-size('base');
  }

  .nf-field-element {
    .nf-element {
      min-height: rem(40);
      padding: rem(10) rem(15);
      border: 1px solid color('gray-lightest');
      border-radius: 9999px;

      @include font-size('form-input-medium');
    }
  }

  .submit-wrap {
    margin-top: rem(45);
    margin-bottom: 0;

    .nf-field-element .nf-element {
      @extend .wp-cto-button, .wp-cto-button--accent;
    }
  }

  .nf-response-msg {
    display: block !important;
    max-height: 0;
    padding: 0;
    text-align: center;
    opacity: 0;
    overflow: hidden;

    p {
      @include query('tablet-down') {
        @include font-size('title-tablet');
      }

      @include query('laptop-up') {
        @include font-size('title-desktop');
      }
    }
  }

  .nf-form-layout {
    max-height: 999px;
    opacity: 1;
    overflow: hidden;

    @include transition();

    form {
      display: block !important;
    }
  }

  .is-form-success {
    .nf-form-layout {
      max-height: 0;
      opacity: 0;
    }

    .nf-form-title {
      max-height: 0;
      opacity: 0;
    }

    .nf-response-msg {
      max-height: 999px;
      padding: rem(40) 0;
      opacity: 1;
    }
  }
}
