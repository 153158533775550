.wp-cto-rich-text {
  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
  }

  ul li,
  ol li {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
  }

  a {
    color: color('action');

    @include transition(color);

    @include hover-focus {
      color: color('secondary');

      .is-page-green & {
        color: color('action-torquoise');
      }
    }
  }
}
