.wp-cto-calculation {
  $this: &;

  &__headline {
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: color('action');

    @include font-stack($font-primary);

    @include query('mobile') {
      padding-bottom: rem(20);

      @include font-size('subheading-mobile');
    }

    @include query('tablet') {
      padding-bottom: rem(30);

      @include font-size('subheading-tablet');
    }

    @include query('laptop') {
      padding-bottom: rem(30);

      @include font-size('subheading-laptop');
    }

    @include query('desktop') {
      padding-bottom: rem(50);

      @include font-size('subheading-desktop');
    }
  }

  &__content {
    background-color: color('helper');
    border-radius: rem(10);

    @include query('mobile') {
      padding: rem(15);
    }

    @include query('tablet') {
      padding: rem(20);
    }

    @include query('laptop') {
      padding: rem(30) rem(27);
    }

    @include query('desktop') {
      padding: rem(30);
    }
  }

  &__element {
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(20);

    &--sum {
      padding-bottom: 0;
      padding-top: rem(20);
      border-top: 1px solid color('action');
    }
  }

  &__title {
    color: color('secondary');

    @include font-stack($font-secondary);

    .is-page-green & {
      color: color('secondary-torquoise');
    }

    @include query('mobile') {
      @include font-size('box-1-mobile');
    }

    @include query('tablet') {
      @include font-size('box-1-tablet');
    }

    @include query('laptop') {
      @include font-size('box-1-laptop');
    }

    @include query('desktop') {
      @include font-size('box-1-desktop');
    }

    #{$this}__element--sum & {
      color: color('action');

      @include query('mobile') {
        @include font-size('box-2-mobile');
      }

      @include query('tablet') {
        @include font-size('box-2-tablet');
      }

      @include query('laptop') {
        @include font-size('box-2-laptop');
      }

      @include query('desktop') {
        @include font-size('box-2-desktop');
      }
    }
  }

  &__value {
    @include font-stack($font-secondary);

    @include query('mobile') {
      @include font-size('box-1-mobile');
    }

    @include query('tablet') {
      @include font-size('box-1-tablet');
    }

    @include query('laptop') {
      @include font-size('box-1-laptop');
    }

    @include query('desktop') {
      @include font-size('box-1-desktop');
    }

    #{$this}__element--sum & {
      color: color('action');

      .is-page-green & {
        color: color('action-torquoise');
      }

      @include query('mobile') {
        @include font-size('box-2-mobile');
      }

      @include query('tablet') {
        @include font-size('box-2-tablet');
      }

      @include query('laptop') {
        @include font-size('box-2-laptop');
      }

      @include query('desktop') {
        @include font-size('box-2-desktop');
      }
    }
  }
}
