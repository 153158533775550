.wp-cto-header {
  $this: &;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: transparent;
  z-index: z('header');

  @include transition(all, 800ms);

  @include query('tablet-down') {
    padding: rem(30) 0;
  }

  @include query('laptop') {
    padding: rem(40) 0;
  }

  @include query('desktop') {
    padding: rem(60) 0;
  }

  .is-page-green & {
    background-color: color('secondary-torquoise') !important;
  }

  &.is-page-scrolled {
    background-color: color('secondary');
    top: -100%;

    .is-page-green & {
      background-color: color('secondary-torquoise') !important;
    }

    @include query('tablet-down') {
      padding: rem(15) 0;
    }

    @include query('laptop') {
      padding: rem(20) 0;
    }

    @include query('desktop') {
      padding: rem(30) 0;
    }

    &#{$this}--scroll-up {
      top: 0;
    }
  }

  .is-open-menu & {
    background-color: color('action');
    bottom: 0;
  }

  .is-open-menu .is-page-green & {
    background-color: color('action-torquoise');
  }

  &__wrapper {
    display: flex;
    flex-direction: row;

    @include container();
    @include transition();

    @include query('tablet-up') {
      justify-content: space-between;
      align-items: center;
    }

    @include query('mobile') {
      justify-content: space-between;
      align-items: center;
    }

    &--desktop {
      .is-open-menu & {
        padding-bottom: rem(30);
        border-bottom: 1px solid color('secondary');
      }

      .is-open-menu.is-page-green & {
        border-bottom: 1px solid color('secondary-torquoise');
      }

      #{$this}__nav {
        @include query('tablet-down') {
          display: none;
        }
      }

      #{$this}__account-buttons {
        @include query('mobile') {
          display: none;
        }
      }
    }

    &--mobile {
      width: 100%;
      max-height: 0;
      opacity: 0;
      overflow: hidden;

      @include transition();

      .is-open-menu & {
        opacity: 1;
        max-height: 100vh;
      }

      #{$this}__nav {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      #{$this}__menu {
        width: 100%;
        flex-direction: column;
      }

      #{$this}__nav-item {
        display: inline-block;
        padding: rem(20) 0;
        border-bottom: 1px solid color('secondary');

        .is-page-green & {
          border-bottom: 1px solid color('secondary-torquoise');
        }
      }

      #{$this}__account-buttons {
        display: flex;
        width: 100%;
        margin-top: rem(40);
        justify-content: space-around;
        align-items: center;

        @include query('tablet') {
          display: none;
        }
      }
    }
  }

  &__logo {
    display: flex;
    cursor: pointer;
    margin-right: auto;

    @include query('tablet-up') {
      margin-right: rem(60);
    }

    svg {
      max-width: 100%;
      width: auto;
      height: 100%;

      @include query('tablet-up') {
        margin-right: rem(60);
      }

      @include query('mobile') {
        max-height: rem(24);
      }

      @include query('tablet') {
        max-height: rem(30);
      }

      @include query('laptop') {
        max-height: rem(30);
      }

      @include query('desktop') {
        max-height: rem(44);
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;

    @include query('mobile') {
      justify-content: space-between;
    }
  }

  &__menu-item {
    cursor: pointer;

    @include query('tablet') {
      margin-right: rem(15);
    }

    @include query('laptop') {
      margin-left: rem(20);
    }

    @include query('desktop') {
      margin-right: rem(30);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__button {
    white-space: nowrap;
  }

  &__burger {
    cursor: pointer;

    @include query('laptop-up') {
      display: none;
    }

    @include query('tablet-down') {
      margin-left: rem(12);
    }

    @include query('laptop') {
      margin-left: rem(17);
    }

    @include query('desktop') {
      margin-right: rem(27);
    }
  }

  &:not(&--widgets) {
    #{$this}__nav {
      @include query('tablet-up') {
        margin-right: rem(30);
      }
    }

    #{$this}__nav-item {
      text-decoration: none;
      color: color('white');
      opacity: 1;
      cursor: pointer;

      @include font-size('menu-item');
      @include transition();

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: color('white');
        opacity: 0;
        transition: inherit;
      }

      @include hover-focus-active {
        text-shadow: -0.015em -0.015em 0, 0.015em 0.015em 0, -0.015em 0.015em 0, 0.015em -0.015em 0;
        opacity: 0.8;

        &::after {
          height: 2px;
          opacity: 0.8;
        }
      }
    }
  }
}
