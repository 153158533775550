.wp-cto-content-container {
  min-height: 100vh;

  @include container();

  &__wrapper {
    @include row();

    @include query('desktop') {
      padding-top: rem(80);
      padding-bottom: rem(180);
    }

    @include query('laptop') {
      padding-top: rem(80);
      padding-bottom: rem(120);
    }

    @include query('tablet') {
      padding-top: rem(70);
      padding-bottom: rem(110);
    }

    @include query('mobile') {
      padding-top: rem(40);
      padding-bottom: rem(80);
    }
  }

  &__main {
    @include query('tablet-up') {
      @include column(9);
    }

    @include query('mobile') {
      @include column(12);
    }

    & > *:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__sidebar {
    @include query('mobile') {
      @include column(12);
    }

    @include query('tablet-up') {
      @include column(3);

      margin-top: rem(10);
    }
  }
}
