.wp-cto-contact-info {
  border-top: 1px solid color('primary');

  @include query('desktop') {
    padding-top: rem(80);
  }

  @include query('laptop') {
    padding-top: rem(50);
  }

  @include query('tablet') {
    padding-top: rem(40);
  }

  @include query('mobile') {
    padding-top: rem(30);
  }

  &__wrapper {
    @include row();
  }

  &__holder {
    @include query('mobile') {
      @include column(12, 'mobile');

      display: flex;
      padding-bottom: rem(30);

      & + & {
        padding-top: rem(30);
        border-top: 1px solid color('primary');
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    @include query('tablet-up') {
      @include column(6);
    }
  }

  &__content {
    @include query('mobile') {
      &--headline {
        flex: 1;
        padding-right: rem(15);
      }

      &--infos {
        flex: 1.5;
        margin-left: auto;
      }
    }
  }

  &__headline {
    text-transform: uppercase;
    letter-spacing: 0.14em;
    color: color('primary');

    @include font-stack($font-primary);

    @include query('mobile') {
      @include font-size('subheading-mobile');
    }

    @include query('tablet') {
      padding-bottom: rem(10);

      @include font-size('subheading-tablet');
    }

    @include query('laptop') {
      padding-bottom: rem(25);

      @include font-size('subheading-laptop');
    }

    @include query('desktop') {
      padding-bottom: rem(30);

      @include font-size('subheading-desktop');
    }
  }

  &__infos {
    list-style: none;
    padding-left: 0;
  }

  &__text {
    color: color('primary');

    @include font-stack($font-primary);

    @include query('mobile') {
      letter-spacing: -0.01em;

      @include font-size('list-mobile');
    }

    @include query('tablet') {
      @include font-size('list-tablet');
    }

    @include query('laptop') {
      @include font-size('list-laptop');
    }

    @include query('desktop') {
      @include font-size('list-desktop');
    }
  }

  &__text + &__text {
    margin-top: rem(15);
  }
}
