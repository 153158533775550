.wp-cto-leverage {
  &__inner {
    @include query('tablet-up') {
      @include container();

      margin-right: 0 !important;
      padding-right: 0 !important;
      max-width: calc(100vw - ((100% - #{rem(1320)} - #{rem(90)}) / 2)) !important;
    }
  }

  &__block {
    display: flex;

    @include query('mobile') {
      flex-direction: column;
    }
  }

  &__content {
    @include query('mobile') {
      width: percentage(1);
      padding: rem(60) rem(20) rem(48) rem(20);
    }

    @include query('tablet') {
      width: calc(#{percentage(6/12)} - #{rem(60)});
      padding: rem(60) 0;
    }

    @include query('laptop-up') {
      width: calc(#{percentage(5/12)} - #{rem(60)});
      padding: rem(75) 0;
    }

    @include query('desktop') {
      padding: rem(100) 0 rem(67) 0;
    }

    &-title {
      margin-top: 0;
      color: color('primary');
      max-width: rem(400);

      @include font-stack($font-secondary);

      @include query('mobile') {
        @include font-size('leverage-heading-mobile');
      }

      @include query('tablet') {
        @include font-size('leverage-heading-tablet');
      }

      @include query('laptop-up') {
        @include font-size('leverage-heading-laptop');
      }

      @include query('desktop-small-up') {
        max-width: rem(600);

        @include font-size('leverage-heading-desktop');
      }
    }

    &-text {
      margin-top: rem(40);

      @include font-size('paragraph-tablet');

      @include query('laptop') {
        margin-top: rem(45);

        @include font-size('paragraph-laptop');
      }

      @include query('desktop-small') {
        margin-top: rem(40);

        @include font-size('paragraph-laptop');
      }

      @include query('desktop-small-up') {
        margin-top: rem(50);

        @include font-size('paragraph-desktop');
      }
    }
  }

  &__social {
    display: flex;

    @include query('mobile') {
      margin-top: rem(30);
    }

    @include query('tablet-up') {
      margin-top: rem(20);
    }

    @include query('laptop-up') {
      margin-top: rem(20);
    }

    @include query('desktop') {
      margin-top: rem(50);
    }

    svg {
      @include query('laptop-down') {
        height: rem(22);
        width: rem(22);
      }

      @include query('tablet-up') {
        margin-right: rem(10);
      }

      @include query('mobile') {
        height: rem(25);
        width: rem(25);
        margin-right: rem(10);
      }

      @include query('desktop-small-up') {
        height: rem(30);
        width: rem(30);
        margin-right: rem(12);
      }
    }
  }

  &__image-wrapper {
    position: relative;

    @include query('mobile') {
      width: percentage(1);
      padding-bottom: 100%;
    }

    @include query('tablet') {
      width: calc(#{percentage(6/12)} + #{rem(60)});
    }

    @include query('laptop-up') {
      width: calc(#{percentage(7/12)} + #{rem(60)});
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include query('tablet-up') {
        object-position: 20% center;
      }

      @include query('mobile') {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__image-overlay {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    z-index: 2;

    @include query('mobile') {
      background-image: image('exclude-rotated.svg');
      height: 100%;
      top: -1px;
      left: 0;
    }

    @include query('tablet-up') {
      background-image: image('exclude.svg');
      top: rem(-0.6);
      bottom: rem(-0.6);
      left: -1px;
    }
  }

  &__button {
    @include query('mobile') {
      margin-top: rem(40);
    }

    @include query('tablet-up') {
      margin-top: rem(40);
    }

    @include query('laptop-up') {
      margin-top: rem(45);
    }

    @include query('desktop-small') {
      margin-top: rem(40);
    }

    @include query('desktop') {
      margin-top: rem(50);
    }
  }
}
