.wp-cto-main-menu {
  display: flex;
  padding-left: 0;
  list-style: none;

  @include query('tablet-down') {
    flex-direction: column;
  }

  .menu-item {
    text-decoration: none;
    color: color('white');
    cursor: pointer;

    @include transition();

    @include hover-focus-active {
      a {
        opacity: 0.8;
        text-shadow: -0.015em -0.015em 0, 0.015em 0.015em 0, -0.015em 0.015em 0, 0.015em -0.015em 0;

        &::after {
          height: 2px;
          opacity: 0.8;
        }
      }
    }

    @include query('tablet-down') {
      display: inline-block;
      padding: rem(20) 0;
      border-bottom: 1px solid color('gray-lightest');

      .is-page-green & {
        border-bottom: 1px solid color('turquoise-lightest');
      }
    }

    a {
      @extend %link-reset;

      position: relative;
      opacity: 1;
      cursor: pointer;

      @include font-size('menu-item');

      transition: inherit;

      @include query('tablet') {
        margin-right: rem(15);
      }

      @include query('laptop') {
        margin-left: rem(20);
      }

      @include query('desktop') {
        margin-right: rem(30);
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: color('white');
        opacity: 0;
        transition: inherit;
      }
    }

    &.current-menu-item {
      a {
        opacity: 0.8;
        text-shadow: -0.015em -0.015em 0, 0.015em 0.015em 0, -0.015em 0.015em 0, 0.015em -0.015em 0;

        &::after {
          height: 2px;
          opacity: 0.8;
        }
      }
    }
  }
}
