.wp-cto-columns {
  @include query('desktop') {
    margin-bottom: rem(100);
  }

  @include query('laptop') {
    margin-bottom: rem(80);
  }

  @include query('tablet') {
    margin-bottom: rem(70);
  }

  @include query('mobile') {
    margin-bottom: rem(60);
  }

  &_holder {
    @include row();

    &.spacing-top-small {
      @include query('laptop-down') {
        margin-top: rem(25);
      }

      @include query('laptop-up') {
        margin-top: rem(50);
      }
    }

    &.spacing-top-medium {
      @include query('laptop-down') {
        margin-top: rem(50);
      }

      @include query('laptop-up') {
        margin-top: rem(100);
      }
    }

    &.spacing-top-large {
      @include query('laptop-down') {
        margin-top: rem(75);
      }

      @include query('laptop-up') {
        margin-top: rem(150);
      }
    }

    &.spacing-bottom-small {
      @include query('laptop-down') {
        margin-bottom: rem(25);
      }

      @include query('laptop-up') {
        margin-bottom: rem(50);
      }
    }

    &.spacing-bottom-medium {
      @include query('laptop-down') {
        margin-bottom: rem(50);
      }

      @include query('laptop-up') {
        margin-bottom: rem(100);
      }
    }

    &.spacing-bottom-large {
      @include query('laptop-down') {
        margin-bottom: rem(75);
      }

      @include query('laptop-up') {
        margin-bottom: rem(150);
      }
    }

    &.spacing-top-none {
      margin-top: 0;
    }

    &.spacing-bottom-none {
      margin-bottom: 0;
    }
  }

  &_column {
    @include column(12);

    align-items: center;

    @include query('mobile') {
      flex-basis: 100% !important;
    }

    .columns_holder--2 & {
      @include query('mobile') {
        @include column(12);
      }

      @include query('tablet') {
        @include column(12);
      }

      @include query('laptop-up') {
        @include column(6);

        max-width: unset;
      }
    }

    .columns_holder--3 & {
      @include query('laptop-up') {
        @include column(4);
      }

      @include query('tablet') {
        @include column(6);

        &:not(:last-child) {
          margin-bottom: rem(32);
        }
      }

      @include query('mobile') {
        @include column(12);

        &:not(:last-child) {
          margin-bottom: rem(32);
        }
      }
    }

    .columns_holder--4 & {
      @include query('laptop-up') {
        @include column(3);
      }

      @include query('tablet') {
        @include column(6);

        &:nth-child(n + 2) {
          margin-top: spacing(4);
        }
      }

      @include query('mobile') {
        @include column(12);

        &:nth-child(n + 1) {
          margin-top: spacing(4);
        }
      }
    }

    .columns_holder--6 & {
      @include query('laptop-up') {
        @include column(4);
      }

      @include query('tablet') {
        @include column(6);
      }

      @include query('mobile') {
        @include column(12);
      }
    }

    & + .columns_column {
      .columns_holder--2 & {
        @include query('tablet') {
          margin-top: spacing(7);

          .notification-box {
            margin-top: - spacing(3.5);
          }
        }

        @include query('mobile') {
          margin-top: spacing(5);

          .notification-box {
            margin-top: - spacing(1.5);
          }
        }
      }
    }
  }
}
