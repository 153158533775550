//[class^="wp-cto-"] {
//  @include font-stack($font-primary);
//}

.wp-cto-leverage__image-wrapper::before {
  @include query('mobile') {
    background-image: url('/assets/images/homepage/exclude-rotated.svg');
  }

  @include query('tablet-up') {
    background-image: url('/assets/images/homepage/exclude.svg');
  }
}

.wp-cto-supporters__slide-content::before {
  background-image: url('/assets/images/homepage/quote.svg');
}

//.wp-cto-3-steps__number {
//  color: #82aeff;
//}

.video-js .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-image: url('/assets/images/homepage/play-button.svg') !important;
  width: 105px !important;
  height: 105px !important;
  border: none !important;
  background-color: transparent !important;
  background-position: center center !important;
  border-radius: 0 !important;
  color: transparent !important;
}

.video-js .vjs-poster {
  background-size: cover !important;
}

.wp-cto-footer__responses {
  color: #82aeff;
  font-size: .875rem;
  line-height: 1rem;
  font-weight: 400;
}
