.wp-cto-button {
  $this: &;

  display: inline-block;
  position: relative;
  padding: rem(12) rem(24);
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  @include transition(all, 350ms);
  @include font-stack($font-primary);
  @include font-size('button');

  @include query('laptop-down') {
    @include font-size('button-small');
  }

  &--small {
    padding: rem(8) rem(20);

    @include font-size('button-small');
  }

  &--narrow {
    padding: rem(8) rem(15);
  }

  &--hero {
    @include query('desktop') {
      padding: rem(12) rem(24);

      @include font-size('button');
    }

    @include query('laptop-down') {
      padding: rem(10) rem(20);

      @include font-size('button-small');
    }

    @include query('desktop-small-up') {
      padding: rem(12) rem(24);

      @include font-size('button');
    }

    .is-page-green & {
      @include query('desktop-small-up') {
        padding: rem(12) rem(24);

        @include font-size('button');
      }
    }
  }

  &--white {
    border: 1px solid color('white');
    border-radius: 9999px;
    background-color: color('white');
    color: color('primary');

    .is-page-green .wp-cto-container & {
      @include query('desktop-small-up') {
        padding: rem(12) rem(24);

        @include font-size('button');
      }
    }

    @include hover {
      border: 1px solid color('white');
      background-color: color('secondary');
      color: color('white');

      .is-page-green & {
        background-color: color('secondary-torquoise');

        #{$this}__icon-circle {
          &::before {
            background-color: color('secondary-torquoise');
          }
        }
      }

      #{$this}__icon-circle {
        border: 1px solid color('white');

        &::before {
          background-color: color('white');
        }
      }
    }
  }

  &--accent {
    color: color('white');
    background-color: color('action');
    border: 1px solid color('action');
    border-radius: 9999px;
    cursor: pointer;

    .is-page-green & {
      background-color: color('action-turquoise');
      border: 1px solid color('action-turquoise');

      &:hover {
        color: color('action-torquoise') !important;
      }

      @include query('desktop-small-up') {
        padding: rem(12) rem(24);

        @include font-size('button');
      }
    }

    @include query('desktop') {
      padding: rem(12) rem(24);

      @include font-size('button');
    }

    @include query('laptop-down') {
      padding: rem(10) rem(20);

      @include font-size('button-small');
    }

    @include query('desktop-small-up') {
      padding: rem(12) rem(24);

      @include font-size('button');
    }

    @include hover {
      background-color: color('white');
      color: color('action');

      .is-page-green & {
        color: color('action-turquoise');
      }
    }
  }

  &--secondary {
    color: color('white');
    background-color: color('primary');
    border-radius: rem(124);
    cursor: pointer;
    border: none;

    @include font-size('base');

    @include query('laptop-down') {
      padding: rem(8) rem(17);

      @include font-size('base-small');
    }

    @include query('desktop-small-up') {
      padding: rem(12) rem(24);

      @include font-size('base');
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__icon-circle {
    display: inline-block;
    position: relative;
    height: rem(18);
    width: rem(18);
    margin-right: rem(10);
    margin-bottom: -4px;
    margin-top: -2px;
    border-radius: 50%;
    border: 1px solid color('black');

    @include transition();

    &::before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
      height: 3px;
      width: 4px;
      background-color: color('white');
      transition: inherit;
    }
  }
}
