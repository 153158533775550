.canonical-card-page {
  padding-top: 50px;
  background: #fbf9fc;
  margin-top: -72px;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }

  &__card {
    max-width: 355px;
    margin: 0 auto;
  }

  &__matching {
    display: flex;
    max-width: 355px;
    width: 100%;
    align-items: center;
    margin: 0 auto 10px;
    padding: 0 10px;
  }

  &__matching-avatar {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__matching-text {
    font-size: 14px;
    line-height: 28px;
    color: #363740;
    margin-left: 10px;
  }
}
