.wp-cto-side-menu {
  padding-left: 0;

  .menu-item {
    margin-bottom: rem(20);
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    @include transition(color);

    @include query('desktop') {
      @include font-size('side-menu-lg');
    }

    @include query('laptop') {
      @include font-size('side-menu-md');
    }

    @include query('tablet-down') {
      @include font-size('side-menu-sm');
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > a {
      text-transform: uppercase;
      color: color('secondary');
      text-decoration: none;
      letter-spacing: 0.08em;

      @include font-stack($font-primary);
      @include transition();
    }

    &.current-menu-item > a {
      text-shadow: -0.02em -0.02em 0, 0.02em 0.02em 0, -0.02em 0.02em 0, 0.02em -0.02em 0;
      color: color('action');
    }

    @include hover {
      text-shadow: -0.02em -0.02em 0, 0.02em 0.02em 0, -0.02em 0.02em 0, 0.02em -0.02em 0;
      color: color('action');
    }
  }
}
