.wp-cto-footer {
  $this: &;

  &__wrapper {
    background-color: color('pale-gray');

    @include query('mobile') {
      padding-top: rem(40);
      padding-bottom: rem(40);
    }

    @include query('tablet-up') {
      padding-top: rem(50);
      padding-bottom: rem(50);
    }

    @include query('laptop-up') {
      padding-top: rem(63);
      padding-bottom: rem(63);
    }

    @include query('desktop') {
      padding-top: rem(85);
      padding-bottom: rem(85);
    }

    .template-homepage & {
      background-color: color('white');
    }
  }

  &__inner {
    @include container();
  }

  &__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include query('tablet-down') {
      padding-bottom: rem(40);
    }

    @include query('mobile') {
      padding-bottom: rem(33);
    }

    @include query('laptop-up') {
      padding-bottom: rem(55);
    }

    @include query('desktop') {
      padding-bottom: rem(82);
    }

    & + & {
      border-top: rem(1) solid color('primary');
      padding-bottom: 0;

      @include query('tablet-down') {
        padding-top: rem(30);
      }

      @include query('laptop-up') {
        padding-top: rem(23);
      }

      @include query('desktop-small-up') {
        padding-top: rem(33);
      }

      @include query('mobile') {
        #{$this}__column {
          padding-top: rem(25);

          &:first-child {
            order: 2;
          }

          & + #{$this}__column {
            padding-top: 0;
          }
        }
      }
    }
  }

  &__column {
    @include query('mobile') {
      width: 100%;
    }

    @include query('laptop-up') {
      padding-right: rem(25);
    }

    & + & {
      @include query('laptop-up') {
        padding-left: rem(25);
        padding-right: 0;
      }
    }

    &--form {
      @include query('mobile') {
        border-top: rem(1) solid color('primary');
        padding-top: rem(33);
      }

      @include query('tablet-up') {
        max-width: 50%;
      }
    }
  }

  &__logo {
    @extend %link-reset;

    svg {
      @include query('tablet-down') {
        width: rem(250);
        height: auto;
      }

      @include query('laptop-up') {
        width: rem(230);
        height: auto;
      }

      @include query('desktop-small-up') {
        width: rem(327);
      }

      @include query('mobile') {
        width: rem(180);
      }
    }
  }

  &__nav-1 {
    @include query('tablet-down') {
      padding-top: rem(25);
    }

    @include query('laptop-up') {
      padding-top: rem(35);
    }

    @include query('desktop') {
      padding-top: rem(45);
    }
  }

  &__nav-2 {
    display: flex;
    flex-direction: row;

    &-cookie-settings {
      color: color('primary');

      @include query('tablet-down') {
        @include font-size('footer-nav-tablet');

        margin-left: 2vw;
      }

      @include query('laptop-up') {
        @include font-size('footer-nav-laptop');

        margin-left: rem(30);
      }

      @include query('desktop-small-up') {
        @include font-size('footer-nav');
      }

      @include query('mobile') {
        @include font-size('footer-nav-2-mobile');
      }

      button {
        @extend %button-reset;

        padding: rem(2);
        background-color: transparent;
        font-weight: 500;

        @include transition(opacity, 350ms);

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__socials {
    @extend %list-reset;

    display: flex;
  }

  &__social {
    line-height: 1;

    @include query('tablet-down') {
      margin-right: 2vw;
    }

    @include query('laptop-up') {
      margin-right: rem(20);
    }

    @include query('mobile') {
      margin-right: rem(13);
    }

    &:last-child {
      margin-right: 0;
    }

    &-link {
      @extend %link-reset;

      @include transition(opacity, 350ms);

      &:hover {
        opacity: 0.5;
      }
    }

    svg {
      @include query('mobile') {
        width: rem(29);
        height: rem(29);
      }

      @include query('tablet') {
        width: rem(32);
        height: rem(32);
      }

      @include query('laptop') {
        width: rem(29);
        height: rem(29);
      }

      @include query('desktop-small-up') {
        width: rem(40);
        height: rem(40);
      }
    }
  }

  &__nav-list {
    @extend %list-reset;

    display: flex;
    color: color('primary');

    @include query('tablet-down') {
      @include font-size('footer-nav-tablet');

      #{$this}__nav-1 & {
        flex-direction: column;
      }
    }

    @include query('laptop-up') {
      @include font-size('footer-nav-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('footer-nav');
    }

    @include query('mobile') {
      @include font-size('footer-nav-mobile');

      #{$this}__nav-1 & {
        padding-bottom: rem(33);
      }

      #{$this}__nav-2 & {
        @include font-size('footer-nav-2-mobile');
      }
    }

    .menu-item {
      @include query('mobile') {
        #{$this}__nav-1 & {
          margin-bottom: rem(18);
        }
      }

      @include query('tablet-down') {
        margin-right: 2vw;

        #{$this}__nav-1 & {
          margin-bottom: rem(10);
          margin-right: auto;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      @include query('laptop-up') {
        margin-right: rem(30);
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        @extend %link-reset;

        padding: rem(2);

        @include transition(opacity, 350ms);

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__form {
    display: flex;
    align-items: flex-end;
    position: relative;

    @include query('tablet-down') {
      flex-direction: column;
      align-items: flex-start;
    }

    @include query('laptop-up') {
      padding-right: rem(87);
    }

    @include query('desktop-small') {
      padding-right: rem(121);
    }

    @include query('desktop') {
      padding-right: rem(121);
    }

    &-input-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: rem(16);
      position: relative;

      @include query('tablet-down') {
        max-width: rem(283);
        margin-right: 0;
        margin-bottom: rem(15);
        width: 100%;
      }

      @include query('laptop') {
        max-width: rem(263);
      }

      @include query('desktop') {
        max-width: rem(263);
      }

      @include query('mobile') {
        max-width: rem(380);
      }
    }

    &-label {
      margin-bottom: rem(16);
      color: color('primary');

      @include query('mobile') {
        @include font-size('form-label-mobile');
      }

      @include query('tablet-up') {
        @include font-size('form-label-tablet');
      }

      @include query('laptop-up') {
        @include font-size('form-label-laptop');
      }

      @include query('desktop-small-up') {
        @include font-size('form-label');
      }
    }

    &-input {
      border-radius: rem(56);
      background-color: color('white');
      border: rem(1) solid color('white');
      color: color('primary');
      padding: rem(12);
      width: 100%;
      margin-top: 0 !important;

      @include font-size('form-input-small');

      @include query('tablet-down') {
        width: rem(283);
        height: rem(34);
      }

      @include query('laptop') {
        width: rem(263);
        height: rem(30);
      }

      @include query('desktop-small-up') {
        width: rem(263);
        height: rem(42);

        @include font-size('form-input');
      }

      @include query('mobile') {
        max-width: rem(380);
        width: 100%;
      }

      &.mce_inline_error {
        color: inherit !important;
        font-weight: 500 !important;
        font-size: rem(12) !important;

        @include query('desktop') {
          font-size: rem(16) !important;
        }
      }

      .template-homepage & {
        background-color: color('pale-gray');
        color: color('primary');
      }
    }

    &-info {
      @include font-size('form-info');

      color: color('primary');

      @include query('laptop-down') {
        @include font-size('form-info-small');
      }

      a {
        color: color('primary');
      }
    }

    .mce_inline_error {
      margin-top: rem(10);
      color: color('action');

      @include font-stack($font-primary);
      @include font-size('form-error');

      .is-page-green & {
        color: color('action-torquoise');
      }

      @include query('laptop-down') {
        @include font-size('form-error-small');
      }
    }

    #mce-responses {
      color: color('secondary');

      @include font-stack($font-primary);
      @include font-size('form-error');

      .is-page-green & {
        color: color('secondary-torquoise');
      }

      @include query('laptop-down') {
        @include font-size('form-error-small');
      }

      .response {
        margin-top: rem(10);

        @include query('mobile') {
          max-width: rem(380);
        }

        @include query('tablet') {
          max-width: rem(283);
        }

        @include query('laptop-up') {
          max-width: rem(263);
        }
      }
    }

    .success {
      @include query('mobile') {
        max-width: rem(380);
      }

      @include query('tablet') {
        max-width: rem(283);
      }

      @include query('laptop-up') {
        max-width: rem(263);
      }
    }

    &-submit {
      @include query('laptop-up') {
        position: absolute;
        right: 0;
        top: rem(33);
      }
    }
  }

  &__rights {
    margin-top: rem(10);
    color: color('primary');

    @include query('mobile') {
      @include font-size('footer-rights-mobile');
    }

    @include query('tablet-up') {
      @include font-size('footer-rights-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('footer-rights-desktop');
    }
  }
}
