@use '../variables/fonts';
@use '../abstracts/mixins';

.uploader {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: initial;
  z-index: 100;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: white;
  width: 100%;
  overflow: hidden;

  &__items {
    padding: 5px 0;
    max-height: 218px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    display: block;
  }

  &__message-wrapper {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 10px;
  }

  &__message {
    margin-bottom: 3px;
  }

  &__progress-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__percentage {
    min-width: 40px;
    margin-left: 5px;
  }

  &__progress {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #E5E5E5;
  }

  &__progress-fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #642780;
    border-radius: 10px;
    transition: width 200ms ease;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    background-color: #642780;
  }

  &__title {
    @include mixins.font(fonts.$secondary);

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: white;
  }

  &__close {
    @include mixins.button-reset;

    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }
}
