@use '../variables/colors';
@use '../variables/radiuses';
@use '../abstracts/mixins';

.form {
  $this: &;

  &-group {
    position: relative;

    &--float {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: white;
      padding: 10px 20px;
      z-index: 100;
    }

    &__wrapper {
      display: flex;
    }

    &__prefix {
      display: block;
      order: -1;
      background-color: colors.$white;
      color: colors.$secondary-shade;
      padding: 10px 0 10px 13px;
      min-height: 50px;

      font-size: 16px;
      line-height: 28px;

      border: 1px solid colors.$secondary-shade;
      border-radius: radiuses.$input 0 0 radiuses.$input;
      border-right: 0;

      &[disabled],
      &[readonly] {
        background-color: colors.$secondary-lighter;
        border-color: colors.$secondary-lighter;
        cursor: text;
      }

      &.invalid {
        border-color: colors.$danger !important;
      }

      &.valid {
        border-color: colors.$success;
      }

      & ~ .form-text:focus {
        border-color: colors.$primary;
      }
    }
  }

  &-counter {
    text-align: right;
    font-size: 12px;
    margin-top: 5px;
  }

  &-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
  }

  &-label {
    font-size: 14px;
    line-height: 28px;

    //& + #{$this}-text {
    //  margin-top: 10px;
    //}
  }

  &-select-wrapper {
    &::after {
      content: '';
      position: absolute;
      bottom: 20px;
      right: 13px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: colors.$secondary-dark transparent transparent;
      pointer-events: none;
    }
  }

  &-select {
    padding-right: 34px;

    &:invalid {
      color: colors.$secondary-shade;
    }
  }

  &-select,
  &-text {
    appearance: none;
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    background-color: colors.$white;
    color: colors.$secondary-dark;
    padding: 10px 13px;
    min-height: 50px;

    font-size: 16px;
    line-height: 28px;

    border: 1px solid colors.$secondary-shade;
    border-radius: radiuses.$input;

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      opacity: 1;
      color: colors.$secondary-shade;
    }

    &:focus {
      border-color: colors.$primary;

      & + .form-group__prefix {
        border-color: colors.$primary;
      }
    }

    &[disabled],
    &[readonly] {
      background-color: colors.$secondary-lighter;
      border-color: colors.$secondary-lighter;
      cursor: text;
    }

    &.invalid {
      border-color: colors.$danger;
    }

    &.valid {
      border-color: colors.$success;
    }

    &:focus ~ .form-password-checks {
      display: block;
    }

    .form-group--prefix & {
      min-width: auto;
      border-left: 0;
      padding-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &-text-prefix {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;

    font-size: 16px;
    line-height: 28px;
    padding: 11px 0 11px 14px;
  }

  &-text-icon {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    max-height: 50px;
    padding: 11px 14px 11px 0;
  }

  &-message {
    //position: absolute;
    //bottom: 0;
    //left: 0;
    display: block;
    width: 100%;

    font-size: 12px;
    line-height: 20px;

    &--error {
      color: colors.$danger;
    }

    &--success {
      color: colors.$success;
    }
  }

  &-checkbox {
    display: block;
    width: 100%;
    position: relative;
    padding-left: 30px;
    cursor: pointer;

    font-size: 12px;
    line-height: 18px;

    a {
      color: inherit;
    }

    &:hover .form-checkbox-checkmark {
      border-color: colors.$secondary-dark;
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .form-checkbox-checkmark {
        border-color: colors.$secondary-dark;

        &:after {
          opacity: 1;
        }
      }
    }

    &-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
      border-radius: radiuses.$input;
      border: 1px solid colors.$secondary-shade;
      transition: border-color 200ms ease, opacity 200ms ease;

      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 9px;
        height: 5px;
        border-left: 1px solid colors.$secondary-dark;
        border-bottom: 1px solid colors.$secondary-dark;
        transform: rotate(-45deg);
        opacity: 0;
      }
    }
  }

  &-password-checks {
    list-style-type: none;
    margin: 10px 0 0;
    padding: 0 0 0 10px;
    display: none;
  }

  &-password-check {
    padding-left: 22px;
    position: relative;
    line-height: 20px;
    font-size: 14px;

    & + & {
      margin-top: 7px;
    }

    &::before {
      content: '';
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: colors.$secondary;
      position: absolute;
      top: 2px;
      left: 0;
    }

    &::after {
      content: '';
      width: 6px;
      height: 4px;
      border-left: 1px solid colors.$white;
      border-bottom: 1px solid colors.$white;
      transform: rotate(-45deg);
      position: absolute;
      top: 7px;
      left: 4px;
      opacity: 0;
    }

    &--valid {
      //color: colors.$success;

      &::before {
        background-color: colors.$success;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &-price-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 10px;
    padding: 0 15px;
  }

  &-price-input-group {
    display: block;
    width: 100%;
    position: relative;
  }

  &-price-input {
    border: 1px solid #c4c4c4;
    border-radius: radiuses.$button;
    font-size: 18px;
    line-height: 48px;
    font-weight: 500;
    text-align: center;
    display: block;
    width: 100%;
    padding: 0 15px 0 25px;
  }

  &-price-prefix {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    line-height: 50px;
    font-weight: 500;
    padding-left: 15px;
  }

  &-title-label {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
}

.new-form-input-group {
  & + & {
    margin-top: 15px;
  }
}

.new-form-input {
  display: flex;
  background: #fff;
  border: 1px solid #989898;
  border-radius: 4px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  font-size: 16px;
  line-height: 48px;

  &__prefix {
    font-size: inherit;
    line-height: inherit;
    display: block;
    color: #363740;

    &--placeholder {
      color: #757575;
    }
  }

  &__field {
    border: none;
    padding: 0;
    background: none;
    font-size: inherit;
    line-height: inherit;
    display: block;
    flex: 1;
    min-width: 0;
    color: #363740;

    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #757575;
      opacity: 1;
    }
  }

  &__textarea {
    border: none;
    background: none;
    font-size: inherit;
    line-height: 28px;
    display: block;
    flex: 1;
    color: #363740;
    margin-right: -15px;
    padding: 10px 15px 10px 0;

    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #757575;
      opacity: 1;
    }
  }
}

.new-form-button {
  background: none;
  padding: 0 30px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  border: 1px solid #602576;
  cursor: pointer;
  color: #602576;

  &--link {
    border-color: transparent;
    padding: 0;

    &:hover {
      background: none;
      text-decoration: underline;
    }

    &-padded {
      padding: 0 30px;
    }
  }

  &--primary {
    background-color: #602576;
    color: white;
    transition: border-color 200ms ease, background-color 200ms ease;

    &:hover {
      border-color: darken(#602576, 5%);
      background-color: darken(#602576, 5%);
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.new-form-button-inverse {
  background: none;
  padding: 0 30px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  border: 1px solid white;
  cursor: pointer;
  color: white;


  &--primary {
    background-color: white;
    color: #602576;
    transition: border-color 200ms ease, background-color 200ms ease;

    &:hover {
      border-color: darken(white, 5%);
      background-color: darken(white, 5%);
    }
  }

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
