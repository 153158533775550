.wp-cto-card-list {
  &__wrapper {
    @include query('mobile') {
      padding-top: rem(60);
      padding-bottom: rem(80);
    }

    @include query('tablet') {
      padding-top: rem(50);
      padding-bottom: rem(50);
    }

    @include query('laptop') {
      padding-top: rem(75);
      padding-bottom: rem(85);
    }

    @include query('desktop-small-up') {
      padding-top: rem(100);
      padding-bottom: rem(100);
    }
  }

  &__inner {
    @include container();
  }

  &__holder {
    @include row();
  }

  &__items {
    @include query('mobile') {
      margin-top: rem(20);
      margin-bottom: rem(40);
    }

    @include query('tablet') {
      margin-top: rem(35);
      margin-bottom: rem(35);
    }

    @include query('laptop-up') {
      margin-top: rem(60);
    }

    @include query('laptop') {
      margin-bottom: rem(60);
    }

    @include query('desktop-small-up') {
      margin-bottom: rem(80);
    }

    @include query('desktop-down') {
      @include row();
    }

    @include query('desktop') {
      @include row($type: 'spread');
    }
  }

  &__content {
    @include query('mobile') {
      @include column(12);
    }

    @include query('tablet-up') {
      @include column(8);
      @include offset(2);
    }

    color: color('primary');
    text-align: center;
  }

  &__title {
    margin-bottom: 0 !important;

    @include font-stack($font-secondary);

    @include query('mobile') {
      @include font-size('leverage-heading-secondary-mobile');
    }

    @include query('tablet') {
      @include font-size('leverage-heading-secondary-tablet');
    }

    @include query('laptop') {
      @include font-size('leverage-heading-secondary-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('leverage-heading-secondary-desktop');
    }
  }

  &__subtitle {
    @include query('mobile') {
      margin-top: rem(40);
    }

    @include query('tablet') {
      margin-top: rem(30);
    }

    @include query('laptop') {
      margin-top: rem(30);
    }

    @include query('desktop-small-up') {
      margin-top: rem(55);
    }

    @include query('mobile') {
      @include font-size('subheading-secondary-mobile');
    }

    @include query('tablet') {
      @include font-size('subheading-secondary-tablet');
    }

    @include query('laptop') {
      @include font-size('subheading-secondary-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('subheading-secondary-desktop');
    }
  }

  &__item {
    margin-top: rem(20);
    margin-bottom: rem(20);

    @include query('mobile') {
      @include column(12);
    }

    @include query('tablet') {
      @include column(4);

      margin-top: 0;
      margin-bottom: 0;
    }

    @include query('laptop') {
      @include column(4);

      margin-top: 0;
      margin-bottom: 0;
    }

    @include query('desktop-small') {
      margin-top: rem(20);
      margin-bottom: 0;
    }

    @include query('desktop') {
      @include column(4, $type:'spread');
    }
  }

  &__item-wrapper {
    height: 100%;
    overflow: hidden;
    background-color: color('pale-gray');
    padding: rem(25) rem(20);

    @include query('mobile') {
      max-width: rem(270);
      margin: auto;
      padding: rem(10) rem(12);
      border-radius: rem(13);
    }

    @include query('tablet') {
      padding: rem(14) rem(11);
      border-radius: rem(13);
    }

    @include query('laptop') {
      padding: rem(16) rem(15);
      border-radius: rem(17);
    }

    @include query('desktop-small-up') {
      padding: rem(22) rem(20);
      border-radius: rem(23);
    }
  }

  &__image {
    width: 100%;

    @include query('tablet-down') {
      max-width: rem(80);
      max-height: rem(80);
    }

    @include query('laptop') {
      max-width: rem(100);
      max-height: rem(100);
    }

    @include query('desktop-small-up') {
      max-width: rem(140);
      max-height: rem(140);
    }

    @include query('mobile') {
      margin-top: rem(4);
      margin-left: rem(3);
    }

    @include query('tablet') {
      margin-top: 0;
      margin-left: rem(6);
    }

    @include query('laptop') {
      margin-top: rem(3);
      margin-left: rem(7);
    }

    @include query('desktop-small-up') {
      margin-top: rem(3);
      margin-left: rem(10);
    }
  }

  &__item-description {
    @include query('mobile') {
      margin-top: rem(12);
    }

    @include query('tablet') {
      margin-top: rem(30);
    }

    @include query('laptop') {
      margin-top: rem(37);
    }

    @include query('desktop-small') {
      margin-top: rem(47);
    }

    @include query('desktop') {
      margin-top: rem(72);
    }

    .is-page-green & {
      @include query('mobile') {
        margin-top: rem(12);
      }

      @include query('tablet') {
        margin-top: rem(30);
      }

      @include query('laptop') {
        margin-top: rem(36);
      }

      @include query('desktop-small') {
        margin-top: rem(50);
      }

      @include query('desktop') {
        margin-top: rem(50);
      }

      p {
        @include query('mobile') {
          @include font-size('card-content-tablet');
        }

        @include query('tablet') {
          @include font-size('card-content-tablet');
        }

        @include query('laptop') {
          @include font-size('card-content-laptop');
        }

        @include query('desktop-small') {
          @include font-size('card-content-desktop');
        }

        @include query('desktop') {
          @include font-size('card-content-desktop');
        }
      }
    }
  }

  &__description,
  &__action {
    @include column(12);

    display: flex;
    justify-content: center;
  }

  &__description {
    text-align: center;

    @include query('mobile') {
      max-width: rem(240);
      margin-left: auto !important;
      margin-right: auto !important;

      @include font-size('card-list-description-mobile');
    }

    @include query('tablet') {
      @include font-size('card-list-description-tablet');
    }

    @include query('laptop-up') {
      @include font-size('card-list-description-laptop');
    }
  }

  &__action {
    @include query('mobile') {
      margin-top: rem(40);
    }

    @include query('tablet') {
      margin-top: rem(35);
    }

    @include query('laptop') {
      margin-top: rem(60);
    }

    @include query('desktop-small-up') {
      margin-top: rem(80);
    }
  }
}
