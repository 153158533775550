.wp-cto-side-navigation {
  $this: &;

  position: relative;

  &__header {
    @extend %button-reset;

    display: inline-block;
    padding: 0 rem(20) rem(30) rem(20);
    margin-left: rem(-20);
    text-transform: uppercase;
    background-color: transparent;
    color: color('action');
    letter-spacing: 0.08em;

    @include font-size('side-menu-sm');
    @include font-stack($font-primary);

    font-weight: 600;

    @include query('tablet-up') {
      display: none;
    }
  }

  &__content {
    @include query('mobile') {
      position: absolute;
      bottom: rem(15);
      left: 0;
      padding: rem(15);
      transform: translateY(100%);
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0 0 6px rgba(54, 55, 62, 0.1);
      background-color: color('white');
      z-index: z('side-menu');

      @include transition();

      #{$this}.is-open & {
        max-height: rem(500);
      }

      #{$this}:not(.is-open) & {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &__arrow {
    margin-left: rem(5);
    transform-origin: center;

    @include transition(transform);

    #{$this}.is-open & {
      transform: rotate(180deg);
    }
  }
}
