.cky-modal {
  &-content {
    @include query('mobile') {
      margin: rem(15) !important;
    }
  }

  &-close {
    @include query('mobile') {
      top: rem(5) !important;
      right: rem(5) !important;
    }
  }
}

.cky-tab {
  &-item {
    @include query('mobile') {
      padding: rem(8) rem(15) !important;
    }
  }

  &-content {
    @include query('mobile') {
      padding: rem(8) rem(10) !important;
    }
  }

  &-desc {
    @include query('mobile') {
      font-size: rem(11) !important;
      line-height: rem(15) !important;
    }
  }
}
