@use '../modules/media';
@use '../variables/colors';
@use '../abstracts/mixins';

.create-flow-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: initial !important;

  @include media.query('tablet-down') {
    padding: 10px 10px 0;
  }

  @include media.query('tablet-up') {
    padding: 50px 10px 0;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }

  &__body {
    max-width: 400px;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__main {
    background: #FFFFFF;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10;
  }

  &__content {
    padding-bottom: 80px;
    position: relative;
  }

  &__actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //display: flex;
    //justify-content: space-between;
    background: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0 30px;
  }

  &__step {
    display: block;
    margin: 0 auto;

    &--offset {
      margin: 0 0 0 15px;
    }
  }

  &__action-wrapper {
    display: flex;
    align-items: center;

    &:first-child {
      justify-content: flex-start;
    }

    &:nth-child(2) {
      justify-content: flex-start;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &__screen {
    min-height: min(560px, calc(100vh - 180px));
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }

  &__contribution-screen {
    min-height: 0;
  }

  &__slug-screen {
    padding: 40px 30px 30px;
  }

  &__slug-logo {
    display: block;
    max-width: 33px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  &__slug-text {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    margin: 10px auto 0;
    max-width: 270px;

    strong {
      font-weight: 500;
    }
  }

  &__slug-form {
    margin-top: 35px;
  }

  &__slug-title {
    margin-top: 45px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    font-weight: 700;
  }

  &__slug-submit {
    padding: 0;
    border: none;
    display: flex;
    width: 100%;
    background: #602576;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 48px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 200ms ease;

    &:hover {
      background-color: darken(#602576, 5%);
    }
  }

  &__profile-screen {
    background-color: #f4f3f5;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__profile-header {
    background-color: white;
    padding: 30px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__profile-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.01em;
  }

  &__profile-content {
    padding: 30px;
  }

  &__avatar-picker {
    display: block;
    width: 84px;
    height: 84px;
    position: relative;
    margin: 20px auto 0;
    cursor: pointer;
  }

  &__avatar {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__avatar-button {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 29px;
    height: 29px;
    border-radius: 100%;
    background-color: rgba(#fff, 0.8);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }

  &__gallery-header {
    background-color: white;
    padding: 20px 30px;
  }

  &__gallery-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
  }

  &__gallery-subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 15px 25px;
  }

  &__gallery-empty {
    padding: 135px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
  }

  &__gallery-drop-zone {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 3px solid #642780;
    background-color: rgba(#642780, 0.5);
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &__cover-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background-color: #F4F3F5;
  }

  &__cover-empty-text {
    max-width: 281px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }

  &__gallery-empty-title {
    margin-top: 25px;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
  }

  &__gallery-empty-description {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }

  &__gallery-button {
    margin-top: 25px;
  }

  &__library {
    padding: 20px 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    background-color: #f4f3f5;
    min-height: 270px;
  }

  &__library-draggable {
    cursor: move;
    width: 100%;
  }

  &__library-item {
    padding-top: 100%;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }

  &__library-item-actions {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    z-index: 8;
  }

  &__library-item-action {
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 200ms ease;

    &:hover {
      background-color: darken(#fff, 5%);
    }

    & + & {
      margin-left: 5px;
    }
  }

  &__library-item-action-prev {
    display: block;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    transform: translateX(2px) rotate(45deg);
    pointer-events: none;
    cursor: pointer;
  }

  &__library-item-action-next {
    display: block;
    width: 8px;
    height: 8px;
    border-top: 2px solid black;
    border-right: 2px solid black;
    transform: translateX(-1px) rotate(45deg);
    pointer-events: none;
    cursor: pointer;
  }

  &__library-item-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;

    &.is-selected::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 3px solid #602576;
      border-radius: 4px;
      z-index: 10;
      pointer-events: none;
    }

    &--image::after {
      content: 'Loading...';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #602576;
      color: #602576;
      font-weight: 700;
      font-size: 12px;
      border-radius: 4px;
    }
  }

  &__library-item-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    z-index: 5;
  }

  &__library-item-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: #202022;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__library-item-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: black;
    z-index: 6;
    object-fit: cover;
    object-position: center;
  }

  &__library-item-empty {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: white;
    border: 1px dashed #c4c4c4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 36px;
    line-height: 36px;
    color: #5b5b5b;
  }

  &__gallery-preview {
    display: block;
    width: 100%;
    padding-top: 125%;
    position: relative;
    overflow: hidden;
  }

  &__gallery-delete-button {
    border: none;
    border-radius: 100%;
    padding: 0;
    position: absolute;
    bottom: 18px;
    right: 18px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }

  &__cover-button {
    border: none;
    position: absolute;
    padding: 0 20px;
    bottom: 18px;
    left: 18px;
    cursor: pointer;
    font-size: 14px;
    line-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }

  &__cover-add-button {
    border: none;
    padding: 0 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #fff;
    font-size: 14px;
    line-height: 40px;
    margin-top: 20px;
  }

  &__gallery-crop-button {
    border: none;
    border-radius: 100%;
    padding: 0;
    position: absolute;
    bottom: 18px;
    left: 18px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }

  &__gallery-preview-image {
    position: absolute;
    display: block;
    // Remove from here...
    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
    object-fit: cover;
    object-position: center;
    // ...to here.
  }

  &__gallery-preview-video {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
  }

  &__contributions {
    background-color: #F4F3F5;
    padding: 30px;
  }

  &__contribution-text {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #240E2E;
  }

  &__contribution-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 13px;
    margin-top: 30px;
  }
}

