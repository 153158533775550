%transition-color {
  @include transition(color, 400ms, ease);
}

%transition-all {
  @include transition(all, 400ms, ease);
}

%transition-all--faster {
  @include transition(all, 100ms, ease);
}

%button-reset {
  outline: none;
  border: none;
  cursor: pointer;
}

%link-reset {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

%list-reset {
  list-style: none;
  padding-left: 0;
}

%ordered-list {
  list-style: none;
  counter-reset: list-count;

  li {
    counter-increment: list-count;

    a {
      position: relative;
      padding-left: rem(20);

      &::before {
        position: absolute;
        left: 0;
        content: counter(list-count);
        color: color('white');
      }
    }
  }
}
