@use '../variables/fonts';
@use '../abstracts/mixins';
@use '../modules/media';

.homepage {
  @include mixins.font(fonts.$secondary);

  overflow-x: hidden;
}

.template-homepage {
  @include media.query('tablet-down') {
    margin-top: -63px;
  }

  @include media.query('tablet-up') {
    margin-top: -72px;
  }
}
