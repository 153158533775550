.contribution-input {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 40px;
  background-color: white;
  border: 1px solid black;
  border-radius: 100px;
  padding: 0 20px;
  text-align: center;
  font-weight: 700;
}
