.wp-cto-modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: rem(40);
  pointer-events: none;
  opacity: 0;
  overflow: auto;
  z-index: z('modal');

  @include transition();

  .is-open-modal & {
    pointer-events: all;
    opacity: 1;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color('dark-overlay');
  }

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: rem(620);
    margin: auto;
    background-color: color('white');
    border-radius: rem(10);
    padding: rem(40);
  }

  &__close {
    position: absolute;
    top: rem(35);
    right: rem(35);
    height: rem(40);
    width: rem(40);
    opacity: 1;
    cursor: pointer;

    @include transition();

    @include hover-focus-active() {
      opacity: 0.6;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: rem(32);
      background-color: color('primary');
      transform-origin: left center;
    }

    &::before {
      transform: rotate(45deg) translateX(-50%);
    }

    &::after {
      transform: rotate(-45deg) translateX(-50%);
    }
  }
}
