$width: 18px;
$height: 2px;

.wp-cto-burger {
  @extend %button-reset;

  position: relative;
  display: block;
  height: 8 * $height;
  width: $width;
  background-color: transparent;
  padding: 0;

  @include transition();

  @include hover {
    opacity: 0.6;
  }

  &__bar {
    display: block;
    position: relative;
    height: $height;
    width: 100%;
    background-color: color('white');

    @include transition();

    .is-open-menu & {
      transform: rotate(45deg);
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -3 * $height;
      width: 100%;
      height: $height;
      background-color: inherit;

      .is-open-menu & {
        top: 0;
        transform: rotate(90deg);
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3 * $height;
      width: 100%;
      height: $height;
      background-color: inherit;

      .is-open-menu & {
        bottom: 0;
        transform: rotate(90deg);
      }
    }
  }
}
