.wp-cto-text-image {
  &__inner {
    @include query('tablet-up') {
      @include container();

      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      max-width: calc(100vw - (100% - #{rem(1275)})) !important;
    }

    @include query('desktop') {
      max-width: unset !important;
    }

    @include query('desktop-down') {
      max-width: 100% !important;
    }
  }

  &__block {
    display: flex;

    @include query('mobile') {
      flex-direction: column-reverse;
    }

    @include query('desktop') {
      position: relative;
    }
  }

  &__content {
    position: relative;
    z-index: 2;

    @include query('mobile') {
      width: percentage(1);
      padding: rem(65) rem(20) 0 rem(35);
    }

    @include query('tablet') {
      width: calc(#{percentage(6/12)});
      padding: rem(105) 0 rem(90) rem(16);
    }

    @include query('laptop-up') {
      width: calc(#{percentage(6/12)});
      padding: rem(100) 0 rem(100) 0;
    }

    @include query('desktop-small') {
      padding: rem(165) 0 rem(140) rem(13);
    }

    @include query('desktop') {
      width: calc(#{percentage(6.5/12)});
      padding: rem(165) 0 rem(140) rem(32);
      margin-left: calc(50% + 3.75rem);
    }

    &-title {
      margin-top: 0;
      margin-bottom: rem(30) !important;
      position: relative;
      color: color('primary');

      &::before {
        content: '';
        display: none;
        position: absolute;
        background-image: image('quote-green.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;

        .is-page-green & {
          display: block;
        }

        @include query('desktop-down') {
          top: rem(-25);
          left: rem(-30);
          width: rem(51);
          height: rem(46);
        }

        @include query('desktop') {
          top: rem(-20);
          left: rem(-32);
          width: rem(61);
          height: rem(56);
        }

        @include query('mobile') {
          top: rem(-18);
          left: rem(-25);
          width: rem(41);
          height: rem(36);
        }
      }

      @include font-stack($font-secondary);

      @include query('mobile') {
        @include font-size('page-headline-mobile');

        max-width: 70%;
        line-height: 1em;
      }

      @include query('tablet') {
        @include font-size('leverage-heading-mobile');
      }

      @include query('laptop-up') {
        @include font-size('leverage-heading-laptop');

        max-width: rem(535);
      }

      @include query('desktop-small-up') {
        @include font-size('leverage-heading-desktop');

        max-width: rem(535);
      }

      .is-page-green & {
        color: color('tertiary-dark');

        @include query('tablet-down') {
          margin-bottom: rem(20) !important;
        }

        @include query('laptop') {
          margin-bottom: rem(18) !important;
        }

        @include query('desktop-small-up') {
          margin-bottom: rem(25) !important;
        }
      }
    }

    &-text {
      @include font-size('paragraph-base-tablet');

      @include query('mobile') {
        @include font-size('card-content-tablet');
      }

      @include query('tablet') {
        max-width: rem(310);
      }

      @include query('laptop-up') {
        max-width: rem(535);

        @include font-size('paragraph-base-laptop');
      }

      @include query('desktop-small-up') {
        max-width: rem(535);

        @include font-size('paragraph-base-desktop');
      }

      .is-page-green & {
        margin-top: 0;
      }
    }
  }

  &__image-wrapper {
    position: relative;

    @include query('mobile') {
      width: percentage(1);
      padding-bottom: 100%;
    }

    @include query('tablet') {
      width: calc(#{percentage(6/12)});
    }

    @include query('laptop-up') {
      width: calc(#{percentage(6/12)} + #{rem(60)});
    }

    @include query('desktop') {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include query('tablet-up') {
        object-position: 20% center;
      }

      @include query('tablet') {
        object-position: center;
      }

      @include query('mobile') {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__image-overlay {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    position: absolute;
    top: 0;
    z-index: 2;

    @include query('mobile') {
      background-image: image('cut-out-secondary-mobile.svg');
      height: 101%;
      top: rem(-1);
      bottom: 0;
    }

    @include query('tablet-up') {
      background-image: image('cut-out.svg');
      top: rem(-0.6);
      bottom: rem(-0.6);
      left: rem(1);
    }
  }
}
