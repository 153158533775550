@use '../variables/colors';

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  counter-reset: homepage-title-list;

  color: colors.$secondary-dark;
  font-family: 'Red Hat Display', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;

  &.modal-open {
    overflow: hidden;
  }
}

input,
textarea,
select,
button,
a {
  outline: none;
}

textarea {
  resize: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
}
