.wp-cto-3-steps {
  $this: &;

  .wp-cto-content-container & {
    @include query('mobile') {
      @include row();
    }
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @include query('mobile') {
      flex-direction: column;
    }

    @include query('tablet-up') {
      flex-direction: row;
    }

    .wp-cto-content-container & {
      @include query('mobile') {
        @include column(10, 'mobile');
        @include offset(1);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include query('mobile') {
      width: 100%;
      padding: rem(40) 0;
      max-width: rem(230);
      margin: auto;
    }

    @include query('tablet') {
      padding: rem(17) rem(20) rem(17) rem(30);
    }

    @include query('tablet-up') {
      flex: 1 1 0;
    }

    @include query('laptop-up') {
      padding: rem(17) rem(50);
    }

    & + * {
      @include query('tablet-up') {
        border-left: rem(1) solid rgba(color('primary'), 0.5);
      }
    }

    &:first-child {
      @include query('mobile') {
        padding-top: 0;
      }

      @include query('tablet-up') {
        padding-left: 0;
      }
    }

    &:last-child {
      @include query('mobile') {
        padding-bottom: 0;
      }

      @include query('tablet-up') {
        padding-right: 0;
      }
    }
  }

  &__img {
    position: relative;
    width: 100%;
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include query('tablet-up') {
      min-height: rem(100);
    }

    .image {
      #{$this}__item:nth-child(1) & {
        @include query('mobile') {
          max-width: rem(126);
        }

        @include query('tablet') {
          max-width: rem(112);
        }

        @include query('laptop') {
          max-width: rem(148);
        }

        @include query('desktop') {
          max-width: rem(190);
        }
      }

      #{$this}__item:nth-child(2) & {
        @include query('mobile') {
          max-width: rem(64);
        }

        @include query('tablet') {
          max-width: rem(57);
        }

        @include query('laptop') {
          max-width: rem(74);
        }

        @include query('desktop') {
          max-width: rem(95);
        }
      }

      #{$this}__item:nth-child(3) & {
        @include query('mobile') {
          max-width: rem(108);
        }

        @include query('tablet') {
          max-width: rem(95);
        }

        @include query('laptop') {
          max-width: rem(126);
        }

        @include query('desktop') {
          max-width: rem(163);
        }
      }
    }
  }

  &__content {
    display: flex;
    padding-top: rem(20);
    margin-top: auto;
    align-items: end;
  }

  &__number {
    margin-bottom: -2px;
    color: color('action');

    @include font-stack($font-secondary);

    .is-page-green & {
      color: color('action-torquoise');
    }

    @include query('mobile') {
      @include font-size('3-step-number-mobile');
    }

    @include query('tablet-up') {
      @include font-size('3-step-number-tablet');
    }

    @include query('laptop-up') {
      @include font-size('3-step-number-laptop');
    }

    @include query('desktop-small-up') {
      @include font-size('3-step-number');
    }
  }

  &__text {
    color: color('primary');

    @include query('mobile') {
      @include font-size('3-step-text-mobile');

      margin-left: rem(15);
      max-width: rem(190);
    }

    @include query('tablet') {
      @include font-size('3-step-text-tablet');

      margin-left: rem(10);
    }

    @include query('laptop') {
      @include font-size('3-step-text-laptop');

      margin-left: rem(13);
    }

    @include query('desktop-small-up') {
      @include font-size('3-step-text');

      margin-left: rem(23);
    }
  }
}
